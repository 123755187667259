import React, { useEffect, useState } from "react";
import db from "../../Firebase";
import Swal from 'sweetalert2'

const CreateCafeModal = ({formData, setFormData, initialFormState, loggedInUser, edit, docId, fetchCafeDetails}) => {
  const Toast = Swal.mixin({
    toast: true,
    background: '#69aba6',
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false) 
  const [deliverHubOptions, setDeliveryHubOptions] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const userQuery = await db.collection('cafe_master')
          .where('cafe_name', '==', formData.cafe_name)
          .where('cafe_location', '==', formData.cafe_location)
          .get();

      if (!userQuery.empty) {
        Toast.fire({
          icon: 'fail',
          title: 'Cafe Already Exist'
        });  
          setLoading(false)
          return true; 
      }
    
    if (edit){
      db.collection("cafe_master").doc(docId).update({
        cafe_name: formData.cafe_name,
        cafe_location: formData.cafe_location,
        delivery_hub: formData.delivery_hub,
        contact_person_name: formData.contact_person_name,
        updated_date: new Date(),
        updated_by: loggedInUser
      }).then(() => {
        resetForm();
        fetchCafeDetails()
        window.modelhideCafe();
        setLoading(false);
        Toast.fire({
          icon: 'success',
          title: 'Cafe Updated Successfully'
        });
      })
    } else {
      const randomNum = Math.floor(100 + Math.random() * 900);
      const username = formData.cafe_name.toLowerCase().replace(/\s+/g, '');
      const password = `${formData.cafe_name.replace(/\s+/g, '')}${randomNum}`;

      try {
        db.collection("cafe_master").add({
            cafe_name: formData.cafe_name,
            cafe_location: formData.cafe_location,
            delivery_hub: formData.delivery_hub,
            type: formData.type,
            user_name: username,
            password: password,
            contact_person_name: formData.contact_person_name,
            created_date: new Date(),
            created_by: loggedInUser
        }).then(() => {
          resetForm();
          fetchCafeDetails()
          window.modelhideCafe();
          setLoading(false);
          Toast.fire({
            icon: 'success',
            title: 'Cafe Added'
          });
        })
    } catch (error) {
        console.error("Error adding cafe: ", error);
    }
    }
    
  };

  const handleChange = (event) => {
    const { id, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const resetForm = () => {
    setFormData({ ...initialFormState });
  };

  const handleClose = () => {
    resetForm();
  };

  const types = ["Cafe", "E-Commerce"];

  const fetchDeliveryHubs = async () => {
    let userRole = localStorage.getItem("role")
    try {
      let query = db.collection("hubs_data")
      if(userRole === "Hub Manager"){
        let hubName = localStorage.getItem("hub_name");
        query = query.where("hub_name", "==", hubName);
      }
      const snapshot = await query.get();
      const hubs = snapshot.docs.map(doc => {
        const data = doc.data();
        return {value: `${data.hub_name}`, label: `${data.hub_name}`}
      })
      setDeliveryHubOptions(hubs)
    } catch (error) {
      console.error('Error fecthing delivery hubs: ', error)
    }
  }

  useEffect(() => {
    fetchDeliveryHubs();
  },[])

  return (
    <>
    {loading && (
        <div className="loader-overlay">
          <div className="">
            <img
              style={{
                height: "6rem",
              }}
              src="images/loader.gif"
            ></img>
          </div>
        </div>
      )}
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Add Cafe/E-Commerce platform
        </h5>
        <button
          type="button"
          class="close"
          aria-label="Close"
          data-bs-dismiss="modal"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="myForm" onSubmit={handleSubmit}>
          <div class="form-group row">
            <div class="flex mb-3">
              <label>Choose Type</label>
              <select
                className={`form-control ${errors.type ? "is-invalid" : ""}`}
                onChange={handleChange}
                id="type"
                value={formData.type}
                required
                disabled={edit}
              >
                <option value="">Select Type</option>
                {types.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            {formData.type != "" && <div class="flex flex-row items-center gap-2">
              <div>
                <label>{formData.type === "Cafe" ? "Cafe Name" : "E-Commerce Platform Name"}<span style={{ color: 'red' }}>*</span></label>
                <input
                  className={`form-control ${
                    errors.name ? "is-invalid" : ""
                  }`}
                  type="text"
                  onChange={handleChange}
                  id="cafe_name"
                  value={formData.cafe_name}
                  required
                  autoComplete="off"
                />
              </div>
            </div>}
            {
              formData.type == "Cafe" && <div class="form-group row">
                <div className="mt-4">
                  <label>Cafe Location<span style={{ color: 'red' }}>*</span></label>
                  <input
                    className={`form-control ${
                    errors.cafe_location ? "is-invalid" : ""
                  }`}
                    type="text"
                    onChange={handleChange}
                    id="cafe_location"
                    value={formData.cafe_location}
                    required
                    autoComplete="off"
                    />
                </div>
              </div>}
          </div>

          {formData.type != "" && <div class="form-group row">
            <div class="col">
            <label>Delivery Hub<span style={{ color: 'red' }}>*</span></label>
              <select
                className={`form-control ${errors.delivery_hub ? "is-invalid" : ""}`}
                onChange={handleChange}
                id="delivery_hub"
                value={formData.delivery_hub}
                required
              >
                <option value="">Select Delivery Hub</option>
                {deliverHubOptions.map((deliveryHub) => (
                  <option key={deliveryHub.value} value={deliveryHub.value}>
                    {deliveryHub.label}
                  </option>
                ))}
              </select>
            </div>
          </div>}
          {formData.type == "Cafe" &&  <div class="form-group row">
              <div>
                <label>Contact Person Name</label>
                <input
                  className={`form-control ${
                    errors.contact_person_name ? "is-invalid" : ""
                  }`}
                  type="text"
                  onChange={handleChange}
                  id="contact_person_name"
                  value={formData.contact_person_name}
                  // required
                  autoComplete="off"
                />
              </div>
            </div>}
            {formData.type != "" && edit && <div class="form-group row">
            <div class="col">
            <label>Username<span style={{ color: 'red' }}>*</span></label>
            <input
                  className={`form-control ${
                    errors.username ? "is-invalid" : ""
                  }`}
                  type="text"
                  onChange={handleChange}
                  id="username"
                  value={formData.username}
                  readOnly
                  autoComplete="off"
                />
            </div>
          </div>}
          {formData.type != "" && edit && <div class="form-group row">
            <div class="col">
            <label>Password<span style={{ color: 'red' }}>*</span></label>
              <input
                  className={`form-control ${
                    errors.password ? "is-invalid" : ""
                  }`}
                  type="text"
                  onChange={handleChange}
                  id="password"
                  value={formData.password}
                  autoComplete="off"
                  readOnly
                />
            </div>
          </div>}

          <div class="modal-footer">
            <button type="submit" value="submit" class="btn btn-success">
              Submit
            </button>
            <button type="button" class="btn btn-light" data-bs-dismiss="modal" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateCafeModal;
