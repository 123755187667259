
import GlobalContext from "./GlobalContext";
import { getUserInfo } from '../Utility';
import { useEffect,useState } from 'react';
import db from '../Firebase';



const GlobalState = ({children}) =>{
    const [permissible_roles, setPermissibleRoles] = useState([]);
    const { loggedIn, userId, username, loggedIn_user,role,hub_name } = getUserInfo();
    const [state_user,setStateUser] = useState({
        "loggedIn":loggedIn,
        "userId":userId,
        "username":username,
        "loggedIn_user":loggedIn_user,
        "role":role,
        "hub_name":hub_name
    })

    useEffect(() => {
        if (!role) {
            return;
        }
        const unsubscribe = db.collection("user_permissions").doc(role).onSnapshot(
            (doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    // console.log(data);
                    if (data.permission) {
                        // console.log("data permission",data.permission)
                        setPermissibleRoles(data.permission);
                    } else {
                        setPermissibleRoles([]);
                    }
                } else {
                    // console.log("No such document!");
                }
            },
            (error) => {
                // console.log("Error getting document: ", error);
            }
        );
    
        return () => unsubscribe();
    }, [state_user]);
    
    return (
        <GlobalContext.Provider value={{
            "permissible_roles":permissible_roles,
            "state_user":state_user,
            "setStateUser":setStateUser

        }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalState;