import React, { useState, useEffect,useContext } from "react";
import { useNavigate} from 'react-router-dom';
import Sidebar from "./Sidebar";
import Swal from 'sweetalert2'
import db from "./Firebase";
import { useCookies } from "react-cookie";
import TopPanel from "./TopPanel";
import Footer from "./Footer";
import GlobalContext from "./context/GlobalContext";
import { handleLogout } from './Utility';

function Location() {
    const {permissible_roles} = useContext(GlobalContext);
    const navigate = useNavigate();

    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        console.log(loggedIn);
        if (!loggedIn) {
            navigate("/login");
        }else{
            if(permissible_roles.length>0){
                if(!permissible_roles.includes('locations')){
                    handleLogout()
                    navigate("/permission_denied");
                }
            }
        }
    }, [navigate,permissible_roles]);
    const [cookies] = useCookies(["permissions"]);
    const rolePermissions = cookies.permissions ? cookies.permissions.Location || [] : [];
    console.log(rolePermissions)
    const [timeOfDay, setTimeOfDay] = useState('');
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const initialFormState = { hub_name: '', area: '', subarea: '', visible_on: 'Internal & External', status: '', updated_date: new Date(), created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormState)
    const [hubNames, setHubNames] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);

    const [totalPages, setTotalPages] = useState(0);

    const [searchQuery, setSearchQuery] = useState("");

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value.replace(/[^\w\s.@/:+\-=]/gi, "") })
    }

    const addNew = () => {
        setEdit(false)
        openModal();
        setSubmit({ hub_name: '', area: '', subarea: '', visible_on: 'Internal & External', status: '', updated_date: new Date(), created_date: new Date() });
    }

    const reset = () => {
        setSubmit({ hub_name: '', area: '', subarea: '', visible_on: 'Internal & External', status: '', updated_date: new Date(), created_date: new Date() })
    }


    useEffect(() => {
        db.collection("hubs_data").onSnapshot((snapshot) => {
            setHubNames(
                snapshot.docs.map((doc) => doc.data().hub_name)
            );
        });
    }, []);

    useEffect(() => {
        db.collection("locations_data").orderBy("updated_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);

    useEffect(() => {
        const getCurrentTimeOfDay = () => {
            const currentTime = new Date().getHours();
            if (currentTime < 12) {
                setTimeOfDay('Good Morning');
            } else if (currentTime >= 12 && currentTime < 18) {
                setTimeOfDay('Good Afternoon');
            } else {
                setTimeOfDay('Good Evening');
            }
        };

        getCurrentTimeOfDay();
    }, []);

    const changeStatusForm = (data, id) => {
        setEdit(true)
        setEditID(id)
        setSubmit({ id: id, hub_name: data.hub_name, area: data.area, subarea: data.subarea, visible_on: data.visible_on, status: data.status, updated_date: new Date(), created_date: new Date() });
        openModal();
    }

    const openModal = () => {

        window.modelshow();
    }

    const closeModal = () => {
        window.modalHide();
    }

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true);


        if (edit) {

            db.collection("locations_data").doc(editID).update({
                'hub_name': submit.hub_name,
                'area': submit.area,
                'subarea': submit.subarea,
                'visible_on': submit.visible_on,
                'status': "1",
                'updated_date': new Date(),
            }).then(() => {

                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Location Updated Successfully'
                })
                closeModal();
                setLoading(false);

                setSubmit({ hub_name: '', area: '', subarea: '', visible_on: 'Internal & External', status: '', updated_date: new Date(), created_date: new Date() })
            })
            // setLoading(false);
        } else {
            db.collection("locations_data").add({
                'hub_name': submit.hub_name,
                'area': submit.area,
                'subarea': submit.subarea,
                'visible_on': submit.visible_on,
                'status': "1",
                'updated_date': new Date(),
                'created_date': new Date(),
            }).then(() => {
                reset();
                // setshow(true)

                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                closeModal();
                setLoading(false);


                Toast.fire({
                    icon: 'success',
                    title: 'Location Added'
                })
            })
        }


    }

    const deleteData = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                db.collection("locations_data").doc(id).delete().then(() => {
                    setSubmit({ hub_name: '', area: '', subarea: '', visible_on: 'Internal & External', status: '', updated_date: new Date(), created_date: new Date() })
                    closeModal();
                    setLoading(false);
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })

            }
        })

    }

    // Filter data based on search query
    const filteredDataLocation = data.filter(({ data }) => {
        // Filter based on search query
        return (
            data.hub_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            data.area.toLowerCase().includes(searchQuery.toLowerCase()) ||
            data.subarea.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    // Determine the range of page numbers to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredDataLocation.slice(indexOfFirstItem, indexOfLastItem);


    // Logic for Pagination
    // const indexOfLastItem = currentPage * itemsPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        const total = Math.ceil(data.length / itemsPerPage);
        setTotalPages(total);
    }, [data.length, itemsPerPage]);


    const renderPageButtons = () => {
        const pageButtons = [];
        // Determine the range of page numbers to display
        let startPage = Math.max(1, currentPage - 5);
        let endPage = Math.min(totalPages, startPage + 9);

        // If the total number of pages is less than 10, adjust the endPage
        if (totalPages <= 10) {
            endPage = totalPages;
        } else {
            // If the current page is near the start, display the first 10 pages
            if (currentPage <= 5) {
                startPage = 1;
                endPage = 10;
            }
            // If the current page is near the end, display the last 10 pages
            else if (currentPage >= totalPages - 4) {
                endPage = totalPages;
                startPage = endPage - 9;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageButtons.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button onClick={() => paginate(i)} className="page-link" style={{ color: "black" }}>{i}</button>
                </li>
            );
        }
        return pageButtons;
    };



    const rolePermission = () => {
        const Toast = Swal.mixin({
            toast: true,
            background: '#d7e7e6',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
        });

        Toast.fire({
            icon: 'error',
            title: 'You are not authorised to do this action'
        });
    }



    return (
        <>
            {loading && ( // Render loader when loading state is true
                <div className="loader-overlay">
                    <div className="">
                        <img style={{
                            height: "6rem"
                        }} src="images/loader.gif"></img>
                    </div>
                </div>
            )}
            <div class="container-scroller">
                <TopPanel />

                <div class="container-fluid page-body-wrapper">


                    <Sidebar />

                    <div class="main-panel">
                        <div class="content-wrapper">

                            <div class="col-lg-12 grid-margin stretch-card">
                                <div class="card">
                                    <div class="card-body">
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <h4 class="card-title">Locations</h4>
                                            <p class="card-description">
                                                {permissible_roles.includes('add_location') ? <><button type="button" class="btn btn-success btn-rounded btn-sm" onClick={() => addNew()}>Add Locations</button></> : <><button type="button" class="btn btn-success btn-rounded btn-sm" onClick={() => rolePermission()}>Add Locations</button></>}

                                            </p>
                                        </div>
                                        <input
                                            style={{
                                                border: "1px solid grey",
                                                padding: "0px 4px 0px 1rem;",
                                                borderRadius: "1rem",
                                                marginTop: "3px",
                                                marginBottom: "1rem",
                                                paddingLeft: "1rem",
                                                height: "32px",
                                                paddingBottom: "0px"
                                            }}
                                            type="text"
                                            placeholder="Search here"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Sr No.
                                                        </th>
                                                        <th>
                                                            Hub Name
                                                        </th>
                                                        <th>
                                                            Area
                                                        </th>
                                                        <th>
                                                            Subarea
                                                        </th>

                                                        <th>
                                                            Visible On
                                                        </th>
                                                        <th>
                                                            Action
                                                        </th>

                                                    </tr>

                                                </thead>
                                                <tbody>

                                                    {currentItems.map(({ id, data }, index) => {
                                                        // Calculate the correct index based on the current page
                                                        const itemIndex = indexOfFirstItem + index + 1;
                                                        return (
                                                            <tr key={id}>
                                                                <td>{itemIndex}</td>
                                                                <td>{data.hub_name}</td>
                                                                <td>{data.area}</td>
                                                                <td>{data.subarea}</td>
                                                                <td>{data.visible_on}</td>
                                                                <td>
                                                                    {permissible_roles.includes('edit_location') ? <>
                                                                        <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => changeStatusForm(data, id)} className="btn btn-dark btn-sm">
                                                                            <i className="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i>
                                                                        </button></> : <>
                                                                        <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => rolePermission()} className="btn btn-dark btn-sm">
                                                                            <i className="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i>
                                                                        </button>
                                                                    </>}

                                                                    {permissible_roles.includes('delete_location') ? <>
                                                                        <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} className="btn btn-dark btn-sm" onClick={() => deleteData(id)}>
                                                                            <i className="menu-icon mdi mdi-delete" style={{ color: "white" }}></i>
                                                                        </button></> : <>
                                                                        <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} className="btn btn-dark btn-sm" onClick={() => rolePermission()} >
                                                                            <i className="menu-icon mdi mdi-delete" style={{ color: "white" }}></i>
                                                                        </button>
                                                                    </>}

                                                                </td>
                                                            </tr>
                                                        );
                                                    })}




                                                </tbody>
                                            </table>

                                        </div>

                                        <ul className="pagination">
                                            <li className="page-item">

                                                <button onClick={() => paginate(currentPage - 1)} className="page-link" disabled={currentPage === 1}>Previous</button>
                                            </li>
                                            {renderPageButtons()}
                                            <li className="page-item">
                                                <button onClick={() => paginate(currentPage + 1)} className="page-link" disabled={currentPage === totalPages}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <Footer />

                    </div>

                </div>

            </div>

            <div class="modal fade" id="exampleModal-2" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel-2">Add Location</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form class="forms-sample" onSubmit={handleSubmit}>
                                <div class="form-group">
                                    <label>Hub Name</label>
                                    <select class="form-select" onChange={handleChange} id="hub_name" value={submit.hub_name} required>
                                        <option>Select Hub Name</option>
                                        {hubNames.map((HubName) => (
                                            <option key={HubName} value={HubName}>
                                                {HubName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label >Area</label>
                                    <input type="text" class="form-control" onChange={handleChange} id="area" value={submit.area} required />
                                </div>
                                <div class="form-group">
                                    <label >Sub Area</label>
                                    <input type="text" class="form-control" onChange={handleChange} id="subarea" value={submit.subarea} required />
                                </div>

                                <div class="form-group">
                                    <label >Visible On</label>
                                    <select class="form-select" onChange={handleChange} id="visible_on" value={submit.visible_on || "Internal & External"}>
                                        <option>Select Visible On</option>
                                        <option value="Internal & External">Internal & External</option>
                                        <option value="Internal">Internal</option>
                                    </select>
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" value="submit" class="btn btn-success">Submit</button>
                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Location