import React, { useState, useEffect,useContext } from "react";
import db from "./Firebase";
import { Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./OnBoardCustomer.css";
import { useNavigate} from 'react-router-dom';
import Moment from "moment";
import { extendMoment } from "moment-range";
import Select from "react-select";
import "./Report.css";
import "./OrderSheet.css";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import "./orderReport.css";
import { collection, getDocs, query, where } from 'firebase/firestore';
import ExportTableToExcel from "./ExportTableToExcel";
import GlobalContext from "./context/GlobalContext";
import { handleLogout } from "./Utility";
function OrderReport() {
    const { permissible_roles } = useContext(GlobalContext);
    const navigate = useNavigate();

    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        if (!loggedIn) {
            navigate("/login");
        }else{
            if(permissible_roles.length>0){
                if(!permissible_roles.includes('order_report')){
                    handleLogout()
                    navigate("/permission_denied");
                }
            }
        }
    }, [navigate,permissible_roles]);
    const moment = extendMoment(Moment);
    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [orderReports, setOrderReports] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [show, setShow] = useState(false);
    const [selectedHub, setSelectedHub] = useState(null);
    const [deliveryExecutiveNames, setDeliveryExecutiveNames] = useState([]);
    const [hubNames, setHubNames] = useState([]);
    const [selectedDeliveryExecutive, setSelectedDeliveryExecutive] = useState();
    const [selectedOrderStatus, setSelectedOrderStatus] = useState();
    const [selectedOrderType, setSelectedOrderType] = useState();
    const [productList, setProductList] = useState([]);
    const [totalQtyOrder, setTotalQtyOrder] = useState(0);
    const [orderSheet, setOrdersheet] = useState({});
    const [orderDataList, setOrderDataList] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [productSummary, setProductSummary] = useState([]);
    const [productListQty, setProductListQty] = useState([]);
    const [deliveryExecutivesMap, setDeliveryExecutivesMap] = useState(new Map());
    const [totalOrdersCount, setTotalOrdersCount] = useState(0);
    const [deliveredOrderCount, setDeliveredOrderCount] = useState(0);
    const [pendingOrdersCount, setPendingOrdersCount] = useState(0);
    const [cancelledrderCount, setCancelledOrderCount] = useState(0);
    const [orderListData, setOrderListData] = useState([]);
    const [uniqueOrders, setUniqueOrders] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showCountList , setShowCountList] = useState(true);

    const orderStatusOptions = [
        { label: "New", value: "0" },
        { label: "Cancelled", value: "2" },
        { label: "Delivered", value: "1" },
    ];

    const orderTypeOptions = [
        { label: "Sub", value: "Sub" },
        { label: "OT", value: "OT" },
        { label: "Re-Order", value: "Re-Order" },
    ];

    const getDeliveryExecutive = async () => {
        const hub_users_snap = await db.collection('hubs_users_data')
            .where('role', '==', 'Delivery Executive')
            .get();
        const de_data = hub_users_snap.docs.map(doc => doc.data());
        const newMap = new Map();
        de_data.forEach(data => {
            const value = `${data.first_name} ${data.last_name}`;
            newMap.set(data.hub_user_id, value);

            //deliveryExecutivesMap.set(details.hub_user_id , `${details.first_name} ${details.last_name}`);
        })

        setDeliveryExecutivesMap(newMap);
    }
    useEffect(() => {

        getDeliveryExecutive();

    }, []);

    const handleSearch = async () => {
        setDataLoaded(false);
        setShowSpinner(true);
    
        const orderlist = [];
        const conditions = [where('delivery_date', '==', moment(deliveryDate).format('YYYY-MM-DD'))];
    
        if (selectedHub) {
            conditions.push(where('hub_name', '==', selectedHub.value));
        }
        if (selectedDeliveryExecutive) {
            conditions.push(where('delivery_exe_id', '==', selectedDeliveryExecutive.value));
        }
        if (selectedOrderStatus) {
            conditions.push(where('status', '==', selectedOrderStatus.value));
        }
        if (selectedOrderType) {
            conditions.push(where('order_type', '==', selectedOrderType.value));
        }
    
        try {
            const q = query(collection(db, 'order_history'), ...conditions);
            const querySnapshot = await getDocs(q);
    
            querySnapshot.forEach((doc) => {
                orderlist.push({ id: doc.id, ...doc.data() });
            });
        } catch (error) {
            console.error("Error fetching documents: ", error);
        }
    
        if (orderlist.length > 0) {
            const uniqueOrdersMap = new Map();
            let totalDeliveredCount = 0;
            let totalPendingCount = 0;
            let totalCancelledCount = 0;
    
            for (const order of orderlist) {
                if (!uniqueOrdersMap.has(order.order_id)) {
                    uniqueOrdersMap.set(order.order_id, order);
    
                    if (order.status === "0") {
                        totalPendingCount += 1;
                    } else if (order.status === "1") {
                        totalDeliveredCount += 1;
                    } else if (order.status === "2") {
                        totalCancelledCount += 1;
                    }
                }
            }
    
            const uniqueOrdersArray = Array.from(uniqueOrdersMap.values());
    
            // Sort by customer name
            uniqueOrdersArray.sort((a, b) => a.customer_name.localeCompare(b.customer_name));
    
            setDeliveredOrderCount(totalDeliveredCount);
            setPendingOrdersCount(totalPendingCount);
            setCancelledOrderCount(totalCancelledCount);
            setOrderDataList(uniqueOrdersArray);
            setFilteredOrders(uniqueOrdersArray);
            setDataLoaded(true);
            setShowSpinner(false);
            setShowCountList(true);
        } else {
            setDataLoaded(false);
            setShowSpinner(false);
        }
    };
    
    const handleReset = () => {
        setDeliveryDate(null);
        setSelectedHub("");
        setSelectedDeliveryExecutive("");
        setOrderReports([]);

    };

    const SpinnerOverlay = () => (
        <div className="spinner-overlay">
            <div className="spinner"></div>
        </div>
    );

    // Determine the range of page numbers to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    //const currentItems = OnboardedCustomer.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const exportTableToPDF = () => {
        const doc = new jsPDF();
        const fileName = "order_report";
    
        // Add title
        doc.text("Order Report", 10, 10);
    
        // Define table columns
        const tableColumn = ["SR No", "Order Id", "Hub", "Delivery Executive", "Customer Name & Phone", "Customer Address", "Order Status", "Order Type", "Order Amount", "Delivery Date", "Delivery Time", "Cancel Time", "Cancel Order Reason"];
        
        // Sort orders by customer name, then by delivery date
        const sortedOrders = filteredOrders.sort((a, b) => {
            if (a.customer_name === b.customer_name) {
                return new Date(a.delivery_date) - new Date(b.delivery_date);
            }
            return a.customer_name.localeCompare(b.customer_name);
        });
    
        // Prepare table rows
        const tableRows = sortedOrders.map((delivery, index) => {
            return [
                index + 1,
                delivery.order_id,
                delivery.hub_name,
                `${deliveryExecutivesMap.get(delivery.delivery_exe_id)}`,
                `${delivery.customer_name} / ${delivery.customer_phone}`,
                `${delivery.delivering_to}`,
                `${delivery.status === "0" ? 'New' : delivery.status === "1" ? 'Delivered' : 'Cancelled'}`,
                `${delivery.order_type}`,
                `${parseInt(delivery.price * delivery.quantity)}`,
                `${moment(delivery.delivery_date).format("DD-MM-YYYY")}`,
                `${delivery.delivery_time ? moment(delivery.delivery_time, 'HH:mm:ss').format('hh:mm A') : ''}`,
                `${delivery.cancel_time ? moment(delivery.cancel_time, 'HH:mm:ss').format('hh:mm A') : ''}`,
                `${delivery.cancel_reason || ''}`
            ];
        });
    
        // Generate the table in the PDF
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30,
            styles: {
                fontSize: 6,
                cellPadding: 1,
            },
        });
    
        // Save the PDF
        doc.save(`${fileName || 'report'}.pdf`);
    };
    const renderPageButtons = () => {

        const pageButtons = [];
        // Determine the range of page numbers to display
        let startPage = Math.max(1, currentPage - 5);
        let endPage = Math.min(totalPages, startPage + 9);

        // If the total number of pages is less than 10, adjust the endPage
        if (totalPages <= 10) {
            endPage = totalPages;
        } else {
            // If the current page is near the start, display the first 10 pages
            if (currentPage <= 5) {
                startPage = 1;
                endPage = 10;
            }
            // If the current page is near the end, display the last 10 pages
            else if (currentPage >= totalPages - 4) {
                endPage = totalPages;
                startPage = endPage - 9;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageButtons.push(
                <li
                    key={i}
                    className={`page-item ${currentPage === i ? "active" : ""}`}
                >
                    <button
                        onClick={() => paginate(i)}
                        className="page-link"
                        style={{ color: "black" }}
                    >
                        {i}
                    </button>
                </li>
            );
        }

        return pageButtons;
    };

    useEffect(() => {
        db.collection("hubs_data").onSnapshot((snapshot) => {
            setHubNames(
                snapshot.docs.map((doc) => ({
                    label: doc.data().hub_name,
                    value: doc.data().hub_name,
                }))
            );
        });
    }, []);

    useEffect(() => {
        const fetchDeliveryExeList = async () => {

            try {
                if (!selectedHub) {
                    setDeliveryExecutiveNames([]);
                    return;
                }
                const snapshot = await db
                    .collection("hubs_users_data")
                    .where("hub_name", "==", selectedHub.value)
                    .get();
                const de = snapshot.docs.map((doc) => {
                    const data = doc.data();
                    return {
                        value: `${data.hub_user_id}`,
                        label: `${data.first_name} ${data.last_name}`,
                    };
                });
                setDeliveryExecutiveNames(de);
            } catch (error) {
                console.error("Error fetching delivery executive:", error);
            }
        };

        fetchDeliveryExeList();
    }, [selectedHub]);



    const handleHubChange = async (selectedOption) => {

        setSelectedHub(selectedOption);
    };

    const handleDEchange = async (selectedOption) => {
        setSelectedDeliveryExecutive(selectedOption);
    };

    const handleOrderStatusChange = async (selectedOption) => {
        setSelectedOrderStatus(selectedOption);
    }

    const handleOrderTypeChange = async (selectedOption) => {
        setSelectedOrderType(selectedOption);
    }

    useEffect(() => {
        const filtered = filteredOrders.filter(order =>
            order.customer_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredOrders(filtered);
    }, [searchTerm]);

    const changeQuantityOrders = async () => {
        setShowSpinner(true);
        setDataLoaded(false);
        const coll = collection(db, 'order_history');
        const q = query(coll, where('delivery_date', '==', moment(deliveryDate).format('YYYY-MM-DD')));
        const snapshot = await getDocs(q);
        let orderList = [];
        snapshot.forEach(doc => {
            const order = doc.data();
            if ('quantity_backup' in order && order.quantity !== order.quantity_backup) {
                //const change_quantity = parseInt(order.quantity_backup) - parseInt(order.quantity);
                orderList.push(order);
            }
        });
        setFilteredOrders(orderList);
        setDataLoaded(true);
        setShowCountList(false);
        setShowSpinner(false);
    }

    return (
        <>
            {showSpinner && ( // Render loader when loading state is true
                <div className="loader-overlay">
                    <div className="">
                        <img style={{
                            height: "6rem"
                        }} src="images/loader.gif"></img>
                    </div>
                </div>
            )}
            <div class="container-scroller">
                <div class="container-fluid">
                    <div class="main-panel" style={{ width: '100%' }}>
                        <div className="panel" style={{ marginTop: "10px", marginBottom: "10px" }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>ORDER REPORT</span>
                                <div className="d-flex">
                                    {dataLoaded &&
                                        // <button className="btn btn-success btn-rounded btn-sm">Export</button>
                                        <ExportTableToExcel
                                            tableId="order_report_table"
                                            fileName="order_report"
                                        />
                                    }
                                     {dataLoaded && (
                                        <button className='btn btn-success btn-rounded btn-sm mx-2' onClick={exportTableToPDF}style={{ marginLeft: '-15%' }} >Export PDF</button>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                <div style={{ marginTop: '2rem' }}>
                                    <input
                                        type="text"
                                        placeholder="Search by Customer Name"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                                <div style={{ marginTop: "7px" }}>
                                    <label style={{ marginBottom: '5px' }}>Delivery Date</label>
                                    <br />
                                    <DatePicker
                                        selected={deliveryDate}
                                        onChange={(date) => setDeliveryDate(date)}
                                        dateFormat="dd/MM/yyyy" // Format for displaying the date
                                        className="datepicker-input"
                                        placeholderText="Date"
                                    />
                                </div>

                                <div>
                                    <label style={{ marginBottom: '10px' }}>Hubs</label>
                                    <Select
                                        options={hubNames}
                                        onChange={handleHubChange}
                                        value={selectedHub}
                                        placeholder="Hub Name"
                                        required
                                    />
                                </div>
                                <div>
                                    <label style={{ marginBottom: '10px' }}>Delivery Executive</label>
                                    <Select
                                        options={deliveryExecutiveNames}
                                        onChange={handleDEchange}
                                        value={selectedDeliveryExecutive}
                                        placeholder="Delivery Executive"
                                        required
                                    />
                                </div>
                                <div>
                                    <label style={{ marginBottom: '10px' }}>Order Status</label>
                                    <Select
                                        options={orderStatusOptions}
                                        onChange={handleOrderStatusChange}
                                        value={selectedOrderStatus}
                                        placeholder="Order Status"
                                        required
                                    />
                                </div>
                                <div>
                                    <label style={{ marginBottom: '10px' }}>Order Type</label>
                                    <Select
                                        options={orderTypeOptions}
                                        onChange={handleOrderTypeChange}
                                        value={selectedOrderType}
                                        placeholder="Order Type"
                                        required
                                    />
                                </div>

                                <div style={{ marginTop: '25px' }}>
                                    <Button variant="outline-success" onClick={handleSearch} size='sm'>
                                        Search
                                    </Button>
                                    
                                    <Button
                                        variant="outline-success"
                                        onClick={handleReset}
                                        style={{ marginLeft: "10px" }}
                                        size='sm'
                                    >
                                        Reset
                                    </Button>
                                </div>
                            </div>

                            <br />
                            <Button variant="outline-info" onClick={changeQuantityOrders} size='sm'>
                                        Change Quantity Order
                                    </Button>
                                    <br/>
                        </div>
                        {/* {showSpinner && <div className="spinner-container"><SpinnerOverlay /></div>} */}
                        {dataLoaded && showCountList && <div className="position-relative end-0 p-3">
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div class="badge badge-pill badge-outline-info" style={{ display: 'flex', flexDirection: 'column', marginLeft: "1rem", fontSize: '17px', fontWeight: '500' }}>
                                        <span>Total Orders</span>
                                        <span>{orderDataList.length}</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div class="badge badge-pill badge-outline-success" style={{ display: 'flex', flexDirection: 'column', marginLeft: "1rem", fontSize: '17px', fontWeight: '500' }}>
                                        <span>Delivered Orders</span>
                                        <span>{deliveredOrderCount}</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div class="badge badge-pill badge-outline-danger" style={{ display: 'flex', flexDirection: 'column', marginLeft: "1rem", fontSize: '17px', fontWeight: '500' }}>
                                        <span>Cancelled Orders</span>
                                        <span>{cancelledrderCount}</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div class="badge badge-pill badge-outline-info" style={{ display: 'flex', flexDirection: 'column', marginLeft: "1rem", fontSize: '17px', fontWeight: '500' }}>
                                        <span>Pending Orders</span>
                                        <span style={{ marginTop: '5px' }}>{pendingOrdersCount}</span>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        <div class="table-responsive">
                            {showCountList ? 
                            <table class="table table-striped" id="order_report_table">
                            <thead>
                                <tr>
                                    <th>Order Id</th>
                                    <th>Hub</th>
                                    <th>Delivery Executive</th>
                                    <th >Customer Name & Phone</th>
                                    <th>Customer Address</th>
                                    <th>Order Status</th>
                                    <th>Order Type</th>
                                    <th>Order Amount</th>
                                    <th>Delivery Date</th>
                                    <th>Delivery Time</th>
                                    <th>Cancel Time</th>
                                    <th>Cancel Order Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataLoaded && filteredOrders.map((order, index) => (
                                    <tr key={index}>
                                        <td>{order.order_id}</td>
                                        <td>{order.hub_name}</td>
                                        <td>{deliveryExecutivesMap.get(order.delivery_exe_id)}</td>
                                        <td className="wrap-text customer-name-phone">{`${order.customer_name} / ${order.customer_phone}`}</td>
                                        <td className="wrap-text customer-name-phone" style={{ maxWidth: '400px' }}>{order.delivering_to}</td>
                                        <td>
                                            {order.status == "0" ? <div class="badge badge-pill badge-outline-info" style={{ marginRight: "1rem" }}>New</div> : <></>}
                                            {order.status == "1" ? <div class="badge badge-pill badge-outline-success" style={{ marginRight: "1rem" }}>Delivered</div> : <></>}
                                            {order.status == "2" ? <div class="badge badge-pill badge-outline-danger" style={{ marginRight: "1rem" }}>Cancelled</div> : <></>}
                                        </td>
                                        <td>{order.order_type}</td>
                                        <td>{parseInt(order.price * order.quantity)}</td>
                                        <td>{moment(order.delivery_date).format("DD-MM-YYYY")}</td>
                                        <td>{order.delivery_time ? moment(order.delivery_time, 'HH:mm:ss').format('hh:mm A') : ''}</td>
                                        <td>{order.cancel_time ? moment(order.cancel_time, 'HH:mm:ss').format('hh:mm A') : ''}</td>
                                        <td>{order.cancel_reason}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                            :
                            <table class="table table-striped" id="order_report_table">
                                <thead>
                                    <tr>
                                        <th>Order Id</th>
                                        <th>Hub</th>
                                        <th>Delivery Executive</th>
                                        <th >Customer Name & Phone</th>
                                        <th>Customer Address</th>
                                        <th>Order Status</th>
                                        <th>Original Quantity</th>
                                        <th>Changed Quantity</th>
                                        <th>Delivery Date</th>
                                        {/* <th>Delivery Time</th>
                                        <th>Cancel Time</th>
                                        <th>Cancel Order Reason</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataLoaded && filteredOrders.map((order, index) => (
                                        <tr key={index}>
                                            <td>{order.order_id}</td>
                                            <td>{order.hub_name}</td>
                                            <td>{deliveryExecutivesMap.get(order.delivery_exe_id)}</td>
                                            <td className="wrap-text customer-name-phone">{`${order.customer_name} / ${order.customer_phone}`}</td>
                                            <td className="wrap-text customer-name-phone" style={{ maxWidth: '400px' }}>{order.delivering_to}</td>
                                            <td>
                                                {order.status == "0" ? <div class="badge badge-pill badge-outline-info" style={{ marginRight: "1rem" }}>New</div> : <></>}
                                                {order.status == "1" ? <div class="badge badge-pill badge-outline-success" style={{ marginRight: "1rem" }}>Delivered</div> : <></>}
                                                {order.status == "2" ? <div class="badge badge-pill badge-outline-danger" style={{ marginRight: "1rem" }}>Cancelled</div> : <></>}
                                            </td>
                                            <td>{order.quantity_backup}</td>
                                            <td>{order.quantity}</td>
                                            <td>{moment(order.delivery_date).format("DD-MM-YYYY")}</td>
                                            {/* <td>{order.delivery_time ? moment(order.delivery_time, 'HH:mm:ss').format('hh:mm A') : ''}</td>
                                            <td>{order.cancel_time ? moment(order.cancel_time, 'HH:mm:ss').format('hh:mm A') : ''}</td>
                                            <td>{order.cancel_reason}</td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrderReport;
