import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import Select from 'react-select';
import { saveAs } from 'file-saver';
import db from './Firebase'; // Import your Firebase configuration

const CurrentSubscriptions = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hubOptions, setHubOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [selectedHub, setSelectedHub] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  useEffect(() => {

    const fetchSubscriptions = async () => {
      try {
        setLoading(true);
        setError(null);
    
        // Fetch vacationed customer ids to exclude from results
        const vacationedCustomerIds = await getVacationData(); 
        console.log(vacationedCustomerIds);
    
        // Get tomorrow's day and date
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1); // Add one day to today's date
        const weekDay = tomorrow.toLocaleString('en-US', { weekday: 'long' }).toLowerCase(); // Get day name in lowercase
    
        // Apply filters for selected hubs and products
        const filters = [
          where('status', '==', '1'), // Only active status
          where('next_delivery_date', '==', tomorrow.toISOString().split('T')[0]), // Next delivery is tomorrow
        ];
    
        // Check if selectedHub and selectedProduct are available, then filter accordingly
        if (selectedHub && selectedHub.length > 0) {
          filters.push(where('hub_name', 'in', selectedHub.map((hub) => hub.value)));
        }
    
        if (selectedProduct && selectedProduct.length > 0) {
          filters.push(where('product_name', 'in', selectedProduct.map((product) => product.value)));
        }
    
        // Query subscriptions collection with filters applied
        const subscriptionQuery = query(collection(db, 'subscriptions_data'), ...filters);
        const snapshot = await getDocs(subscriptionQuery);
    
        if (snapshot.empty) {
          setCustomers([]);
        } else {
          // Process subscriptions data
          const customerData = snapshot.docs
            .map((doc) => {
              const data = doc.data();
    
              // Determine quantity based on weekday-specific quantity or total quantity
              const weekdayQuantity = data[weekDay] || 0; // Use the relevant weekday field
              const finalQuantity = weekdayQuantity > 0 ? weekdayQuantity : data.quantity;
    
              return {
                id: doc.id,
                ...data,
                finalQuantity,
              };
            })
            .filter((customer) => !vacationedCustomerIds.has(customer.customer_id)); // Exclude vacationed customers
    
          // Calculate total quantity
          const total = customerData.reduce((acc, customer) => acc + (customer.finalQuantity || 0), 0);
          setTotalQuantity(total);
    
          setCustomers(customerData);
        }
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
        setError('Failed to fetch subscriptions');
      } finally {
        setLoading(false);
      }
    };
    
    

    fetchSubscriptions();
  }, []);

  function getStartAndEndTimestamps(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.error("Invalid Date:", dateString);
      return { start: null, end: null };
    }
    const startOfDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0,
      0
    ).getTime();
    const endOfDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59,
      999
    ).getTime();
    return {
      start: startOfDay,
      end: endOfDay,
    };
  }
// Fetch vacation data 
async function getVacationData() {
  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1); // Getting tomorrow's date
  console.log(tomorrowDate)

  try {
    const vacationDocs = await db.collection("customers_vacation").get();
    const vacationDataSet = new Set();
    
    // Iterating over the documents
    for (const doc of vacationDocs.docs) {
      try {
        const vacationData = doc.data();
        
        // Converting Firestore Timestamp to Date object
        const startDate = vacationData.start_date.toDate(); // Converting to JavaScript Date
        const endDate = vacationData.end_date.toDate(); // Converting to JavaScript Date
        
        startDate.setHours(0, 0, 0, 0);  // Set the time to 00:00:00
        endDate.setHours(23, 59, 59, 999);  // Set to the end of the day for comparison
        
        // Log start and end dates for debugging
        console.log(startDate, " ", endDate, " ", tomorrowDate);

        // Check if tomorrow's date is within the vacation range
        if (startDate <= tomorrowDate && tomorrowDate <= endDate) {
          vacationDataSet.add(vacationData.customer_id);
        }
      } catch (error) {
        console.log(`Error processing vacation record ${doc.id}: ${error}`);
      }
    }
    
    return vacationDataSet;
  } catch (error) {
    console.log('Error fetching vacation data:', error);
    return new Set();  // Ensure you return an empty Set if error occurs
  }
}


// Fetch subscriptions data including vacation filtering
const fetchSubscriptions = async () => {
  try {
    setLoading(true);
    setError(null);

    // Fetch vacationed customer ids to exclude from results
    const vacationedCustomerIds = await getVacationData(); 
    console.log(vacationedCustomerIds);

    // Get tomorrow's date (strictly after today)
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // Add one day to today's date

    // Apply filters for selected hubs and products
    const filters = [
      where('status', '==', '1'), // Only active status
      where('next_delivery_date', '==', tomorrow.toISOString().split('T')[0]), // Next delivery is tomorrow
    ];

    // Check if selectedHub and selectedProduct are available, then filter accordingly
    if (selectedHub && selectedHub.length > 0) {
      filters.push(where('hub_name', 'in', selectedHub.map((hub) => hub.value)));
    }

    if (selectedProduct && selectedProduct.length > 0) {
      filters.push(where('product_name', 'in', selectedProduct.map((product) => product.value)));
    }

    // Query subscriptions collection with filters applied
    const subscriptionQuery = query(collection(db, 'subscriptions_data'), ...filters);
    const snapshot = await getDocs(subscriptionQuery);

    if (snapshot.empty) {
      setCustomers([]);
    } else {
      // Filter out the vacationed customers
      const customerData = snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((customer) => !vacationedCustomerIds.has(customer.customer_id)); // Exclude vacationed customers
        

         // Calculate total quantity
         const total = customerData.reduce((acc, customer) => acc + (customer.quantity || 0), 0);
         setTotalQuantity(total);
      setCustomers(customerData);
    }
  } catch (error) {
    console.error('Error fetching subscriptions:', error);
    setError('Failed to fetch subscriptions');
  } finally {
    setLoading(false);
  }
};

  // Handle CSV export with error messaging
  const exportToCSV = () => {
    if (customers.length === 0) {
      alert('No data available to export');
      return;
    }

    const headers = [
      'SN', 'Customer ID', 'Customer Name', 'Product Name', 'Quantity', 
      'Hub Name', 'Price', 'Next Delivery Date', 'Subscription Type'
    ];

    const rows = customers.map((customer, index) => [
      index + 1, customer.customer_id || 'N/A', customer.customer_name || 'N/A',
      customer.product_name || 'N/A', customer.quantity || 'N/A', customer.hub_name || 'N/A',
      customer.price || 'N/A', customer.next_delivery_date || 'N/A', customer.subscription_type || 'N/A'
    ]);

    const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'subscriptions.csv');
  };

  return (
    <div className="main-container">
      <h3>Delivery Subscriptions</h3>
      <p>Total Records: {customers.length}</p>
      <p>Total Quantity: {totalQuantity}</p> {/* Display total quantity */}
      <div className="filters-container">
        <div className="filter-row">
          <div className="filter-item">
            <label>Hub</label>
            <Select
              options={hubOptions}
              isMulti
              onChange={(selected) => setSelectedHub(selected)}
            />
          </div>

          <div className="filter-item">
            <label>Product</label>
            <Select
              options={productOptions}
              isMulti
              onChange={(selected) => setSelectedProduct(selected)}
            />
          </div>
        </div>

        <button onClick={fetchSubscriptions} className="btn btn-primary">Filter</button>
      </div>
      <p>Total Records: {customers.length}</p>
      <button onClick={exportToCSV} className="btn btn-success">Export CSV</button>

      {loading && <p>Loading...</p>}
      {error && <p className="text-danger">{error}</p>}

      <table className="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Customer Name</th>
            <th>Customer Phone</th>
            <th>Hub</th>
            <th>Product</th>
            <th>Quantity</th>
            <th>Subscription Type</th>
            
            <th>Next Delivery Date</th>
          </tr>
        </thead>
        <tbody>
  {customers.map((customer, index) => (
    <tr key={customer.id}>
      <td>{index + 1}</td>
      <td>{customer.customer_name}</td>
      <td>{customer.customer_phone}</td>
      <td>{customer.hub_name}</td>
      <td>{customer.product_name}</td>
      <td>{customer.finalQuantity}</td> {/* Display final quantity */}
      <td>{customer.subscription_type}</td>
      <td>{customer.next_delivery_date}</td>
    </tr>
  ))}
</tbody>

      </table>
    </div>
  );
};

export default CurrentSubscriptions;
