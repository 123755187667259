import React, { useState, useEffect, useRef,useContext } from "react";
import { useNavigate } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Swal from 'sweetalert2'
import db from "./Firebase";
import Select from 'react-select';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from './Firebase';
import TopPanel from "./TopPanel";
import Footer from "./Footer";
import './profiilePage.css';
import { collection, getDocs, query,  where ,getCountFromServer } from 'firebase/firestore';
import { getUserInfo,handleLogout } from "./Utility";
import GlobalContext from "./context/GlobalContext";
function Customers() {
    const {permissible_roles} = useContext(GlobalContext);
    console.log("permisssion at customers",permissible_roles)
    const navigate = useNavigate();
    const [newUserCount, setNewUserCount] = useState(0);
    /** show new user count */
    useEffect(() => {
        // Function to fetch the count of new users for today
        const fetchNewUserCount = async () => {
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Set the time to the start of the day

            const q = query(collection(db, "customers_data"), where("registered_date", ">=", today));
            console.log(q);
         
            const snapshot = await getDocs(q);
            setNewUserCount(snapshot.size);
       
        };
        // Fetch the count when the component mounts
        fetchNewUserCount();

        // Optionally, set up an interval to fetch the count every day
        const intervalId = setInterval(fetchNewUserCount, 86400000); // 86400000ms = 24 hours

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);
    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        if (!loggedIn) {
            navigate("/login");
        }else{
            if(permissible_roles.length>0){
                if(!permissible_roles.includes('customers')){
                    handleLogout()
                    navigate("/permission_denied");
                }
            }
        }
    }, [navigate,permissible_roles]);
    const moment = extendMoment(Moment);
    const [timeOfDay, setTimeOfDay] = useState('');
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const initialFormState = { customer_id: '', customer_image: null, customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', alt_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', platform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormState)
    const {  loggedIn_user } = getUserInfo();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);

    const [hubNames, setHubNames] = useState([]);
    const [selectedHub, setSelectedHub] = useState(null);
    const [locationOptions, setLocationOptions] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [sourceCategory, setSourceCategory] = useState("");
    const [deliveryExecutiveID, setDeliveryExecutiveID] = useState("");
    const [nod, setNod] = useState("");

    
    const [customers, setCustomers] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [collectionSize, setCollectionSize] = useState(null);
    

    const getCustomerPage=()=>{
        navigate("/customers_report");
    }
    useEffect(() => {
        fetchInitialData();
        fetchCollectionSize();
    }, []);

    const fetchCollectionSize = async () => {
        try {
            const coll = collection(db, 'customers_data');
            const snapshot = await getCountFromServer(query(coll));
            setCollectionSize(snapshot.data().count);
        } catch (error) {
            console.error("Error fetching collection size: ", error);
        }
    };

    const fetchInitialData = async () => {
        setLoading(true);
        const snapshot = await db.collection("customers_data")
            .orderBy("registered_date", "desc")
            .limit(20)
            .get();

        const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
        const customerData = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
        }));
        setCustomers(customerData);
        setLastVisible(lastVisibleDoc);
        setLoading(false);
    };

    const fetchMoreData = async () => {
        if (lastVisible) {
            setLoading(true);
            const snapshot = await db.collection("customers_data")
                .orderBy("registered_date", "desc")
                .startAfter(lastVisible)
                .limit(20)
                .get();

            const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
            const newCustomerData = snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));
            setCustomers((prevCustomers) => [...prevCustomers, ...newCustomerData]);
            setLastVisible(lastVisibleDoc);
            setLoading(false);
        }
    };
    const fetchData = async (searchTerm = "") => {
        setLoading(true);
        try {
            const filteredCustomers = new Map();
            const searchTermLower = searchTerm.toLowerCase();
            const endTerm = searchTermLower.replace(/.$/, (c) =>
                String.fromCharCode(c.charCodeAt(0) + 1)
            );
    
            // Logs to verify search term
            console.log("Search Term (Lowercased):", searchTermLower);
    
            // Firestore query for name (dynamic)
            const nameQuery = db.collection("customers_data")
                .orderBy("customer_name")
                .startAt(searchTermLower)
                .endAt(endTerm)
                .limit(20)
                .get();
    
            // Firestore query for phone number (case-insensitive comparison)
            const phoneQuery = db.collection("customers_data")
                .where("customer_phone", ">=", searchTermLower)
                .where("customer_phone", "<", endTerm)
                .limit(20)
                .get();
    
            // Optional: Add query for exact match
            const exactNameQuery = db.collection("customers_data")
                .where("customer_name", "==", searchTerm)
                .get();
    
            const exactPhoneQuery = db.collection("customers_data")
                .where("customer_phone", "==", searchTerm)
                .get();
    
            const [nameSnapshot, phoneSnapshot, exactNameSnapshot, exactPhoneSnapshot] = await Promise.all([
                nameQuery,
                phoneQuery,
                exactNameQuery,
                exactPhoneQuery,
            ]);
    
            console.log("Name Snapshot:", nameSnapshot.docs.length);  // Log size
            console.log("Phone Snapshot:", phoneSnapshot.docs.length); // Log size
    
            // Combine results from all queries
            nameSnapshot.docs.forEach((doc) => {
                filteredCustomers.set(doc.id, { id: doc.id, data: doc.data() });
            });
    
            phoneSnapshot.docs.forEach((doc) => {
                filteredCustomers.set(doc.id, { id: doc.id, data: doc.data() });
            });
    
            exactNameSnapshot.docs.forEach((doc) => {
                filteredCustomers.set(doc.id, { id: doc.id, data: doc.data() });
            });
    
            exactPhoneSnapshot.docs.forEach((doc) => {
                filteredCustomers.set(doc.id, { id: doc.id, data: doc.data() });
            });
    
            // Log the resulting customer data
            console.log("Filtered Customers:", Array.from(filteredCustomers.values()));
    
            setCustomers(Array.from(filteredCustomers.values())); // Update the state with filtered data
        } catch (error) {
            console.error("Error fetching customers:", error);
        } finally {
            setLoading(false);
        }
    };
    
    // Handle search input change
    const handleInputChange = (e) => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm); // Update state
        fetchData(searchTerm); // Fetch results
    };
    
    // Trigger search on 'Enter' key press
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch(); // Perform the search when Enter is pressed
        }
    };
    
    const handleSearch = () => {
        setCustomers([]); // Clear previous results
        setLastVisible(null); // Reset pagination
        fetchData(searchQuery); // Fetch data based on the current query
    };
    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown); // Add event listener for key down
        return () => {
            document.removeEventListener('keydown', handleKeyDown); // Cleanup event listener
        };
    }, [searchQuery]);
    
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = customers.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        const total = Math.ceil(customers.length / itemsPerPage);
        setTotalPages(total);
    }, [customers.length, itemsPerPage]);

    const renderPageButtons = () => {
        const pageButtons = [];
        
        let startPage = Math.max(1, currentPage - 5);
        let endPage = Math.min(totalPages, startPage + 9);

        
        if (totalPages <= 10) {
            endPage = totalPages;
        } else {
            
            if (currentPage <= 5) {
                startPage = 1;
                endPage = 10;
            }
            
            else if (currentPage >= totalPages - 4) {
                endPage = totalPages;
                startPage = endPage - 9;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageButtons.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button onClick={() => paginate(i)} className="page-link" style={{ color: "black" }}>{i}</button>
                </li>
            );
        }
        return pageButtons;
    };

    const categoryList = [
        { label: "Lead", value: "Lead" },
        { label: "One Time", value: "One Time" },
        { label: "Subscribed", value: "Subscribed" },
        { label: "Unqualified", value: "Unqualified" },
    ];

    const sourceList = [
        { label: "Website", value: "Website" },
        { label: "Friends", value: "Friends" },
        { label: "Newspaper", value: "Newspaper" },
        { label: "Ads", value: "Ads" },
    ];

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const fileInputRef = useRef(null);
    useEffect(() => {
        db.collection("hubs_data").onSnapshot((snapshot) => {
            setHubNames(
                snapshot.docs.map((doc) => ({ label: doc.data().hub_name, value: doc.data().hub_name }))
            );
        });
    }, []);

    useEffect(() => {
        fetchLocations();
    }, [selectedHub]);

    const fetchLocations = async () => {
        try {
            if (!selectedHub) {
                setLocationOptions([]);
                return;
            }
            const snapshot = await db.collection('locations_data').where('hub_name', '==', selectedHub.value).get();
            const locations = snapshot.docs.map(doc => {
                const data = doc.data();
                return { value: `${data.area}, ${data.subarea}`, label: `${data.area}, ${data.subarea}` };
            });
            setLocationOptions(locations);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    const handleCategoryChange = (selectedOption) => {
        setSelectedCategory(selectedOption);
        setSubmit({ ...submit, customer_category: selectedOption.value });
    };

    const handleSourceChange = (selectedOption) => {
        setSourceCategory(selectedOption);
        setSubmit({ ...submit, source: selectedOption.value });
    };



    const handleHubChange = (selectedOption) => {
        setSelectedHub(selectedOption);
        setSelectedLocation([]);

        setSubmit({ ...submit, hub_name: selectedOption.value });
    };

    const handleLocationChange = (selectedOption) => {
        setSelectedLocation(selectedOption);
        setSubmit({ ...submit, location: selectedOption.value });
        
        db.collection("hubs_locations_data")
            .where("location", "==", selectedOption.value)
            .get()
            .then((querySnapshot) => {

                if (!querySnapshot.empty) {
                    const data = querySnapshot.docs[0].data();

                    
                    const did = data.delivery_exe_id;
                    
                    
                    setDeliveryExecutiveID(did)
                    setNod("found")
                } else {
                    console.log("No matching location found in hubs_locations_data collection.");
                    
                    setDeliveryExecutiveID("notfound")
                }
            })
            .catch((error) => {
                console.error("Error searching for location:", error);
            });
    };



    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleDateRange = () => {

        const startDateTimestamp = startDate ? startDate.getTime() : 0;
        const endDateTimestamp = endDate ? endDate.getTime() : new Date().getTime();

        db.collection("customers_data")
            .where("created_date", ">=", new Date(startDateTimestamp))
            .where("created_date", "<=", new Date(endDateTimestamp))
            .orderBy("registered_date", "desc")
            .onSnapshot((snapshot) => {
                setData(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                );
            });
    };

    const handleReset = () => {
        setStartDate(null);
        setEndDate(null);
        fetchInitialData();

    };

    const navi = (name) => {
        
        
        
        navigate(`/profile/${name}`);
    }


    
    
    
    
    const handleChange = (e) => {
        const { id, name, value, type, checked, files } = e.target;
        let newValue = type === 'checkbox' ? checked : value;

        if (type === 'radio') {
            
            newValue = value;
        } else if (id === 'customer_phone' || id === 'pincode' || id === 'alt_phone') {
            
            newValue = value.replace(/\D/g, '');
        } else if (type === "file") {
            
            const file = files[0]; 
            
            newValue = file
        } else {
            
            newValue = value.replace(/[^\w\s.@/:+\-=]/gi, "");
        }

        setSubmit(prevState => ({
            ...prevState,
            [name]: newValue
        }));
    };



    const addNew = () => {
        fileInputRef.current.value = '';
        setSelectedHub("");
        setSelectedLocation([]);
        setSelectedCategory("");
        setSourceCategory("");
        setEdit(false);
        openModal();
        
        setDeliveryExecutiveID("")
        setNod("")
        setSubmit({ customer_id: '', customer_image: null, customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', alt_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', platform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() });
    }

    const reset = () => {
        setSubmit({ customer_id: '', customer_image: null, customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', alt_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', platform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() })
    }
    const filterData = (cat) => {
        if (cat === "All") {
            db.collection("customers_data").orderBy("registered_date", "desc").onSnapshot((snapshot) => {
                setData(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                );
            });
        } else {
            db.collection("customers_data").where("customer_category", "==", cat).onSnapshot((snapshot) => {
                setData(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                );
            });
        }

    }

    const changeStatusForm = (event, data, id) => {
        event.stopPropagation();
        setSelectedHub({ value: data.hub_name, label: data.hub_name });

        setSelectedLocation([{ value: data.location, label: data.location }]);
        setSelectedCategory({ value: data.customer_category, label: data.customer_category })
        setSourceCategory({ value: data.source, label: data.source })
        
        setDeliveryExecutiveID(data.delivery_exe_id === "" ? "notfound" : data.delivery_exe_id)
        setNod("found")
        setEdit(true)
        setEditID(id)
        setSubmit({ id: id, customer_id: data.customer_id, customer_image: data.customer_image, customer_type: data.customer_type, customer_name: data.customer_name, customer_category: data.customer_category, hub_name: data.hub_name, customer_phone: data.customer_phone, alt_phone: data.alt_phone, customer_email: data.customer_email, dob: moment(data.dob).format("YYYY-MM-DD"), anniversary_date: moment(data.anniversary_date.toDate().toISOString()).format("YYYY-MM-DD"), customer_address: data.customer_address, flat_villa_no: data.flat_villa_no, floor: data.floor, landmark: data.landmark, location: data.location, pincode: data.pincode, gender: data.gender, platform: data.platform, delivery_exe_id: data.delivery_exe_id, wallet_balance: data.wallet_balance, credit_limit: data.credit_limit, status: data.status, source: data.source, updated_date: new Date(), created_date: new Date() });
        openModal();
    }

    const openModal = () => {
        
        window.modelshow();
    }

    const closeModal = () => {
        window.modalHide();
    }
    function generateCustomerId() {
        const now = new Date();
        const timestamp = now.getTime(); 
        const random4Digits = Math.floor(Math.random() * 10000).toString().padStart(4, '0'); 

        
        const customerId = (timestamp % 10000).toString().padStart(4, '0') + random4Digits;

        return customerId;
    }

    const checkPhoneExists = async (phone) => {
        try {
            const phoneSnapshot = await db.collection("customers_data").where('customer_phone', '==', phone).get();
            return !phoneSnapshot.empty;
        } catch (error) {
            console.error('Error checking route existence: ', error);
            return false;
        }
    };

    const addActivity = (object, action, description, customerId, submit) => {

        db.collection("customer_activities").add({
            'customer_id': customerId,
            'customer_name': submit.customer_name,
            'customer_phone': submit.data.customer_phone,
            'customer_address': submit.customer_address,
            'hub_name': submit.hub_name,
            'delivery_exe_id': deliveryExecutiveID,
            'user': 'Server',
            'object': object,
            'action': action,
            'description': description,
            'platform' :"CRM",
            'date': new Date(),
            'created_date': new Date(),
        }).then(() => {
            console.log('added activiteies');
        })
    }

    const handleSubmit = async (e) => {
        
        e.preventDefault()
        const customerId = generateCustomerId();

        if (submit.customer_phone.length != 10) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Phone number should not less than 10 digit',
            });
            return;
        }


        if (edit) {

            setLoading(true);

            if (submit.customer_image && typeof submit.customer_image !== "string") {
                const storageRef = ref(storage, `users/${submit.customer_image.name}`);
                const uploadTask = uploadBytesResumable(storageRef, submit.customer_image);

                uploadTask.on(
                    "state_changed",
                    snapshot => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    },
                    error => {
                        console.error("Error uploading image: ", error);
                    },
                    () => {
                        
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                            db.collection("customers_data").doc(editID).update({
                                'customer_id': submit.customer_id,
                                'customer_image': downloadURL,
                                'customer_type': submit.customer_type,
                                'customer_name': submit.customer_name,
                                'customer_category': submit.customer_category === "" ? "Lead" : submit.customer_category,
                                'hub_name': submit.hub_name,
                                'customer_phone': submit.customer_phone,
                                'alt_phone': submit.alt_phone,
                                'customer_email': submit.customer_email,
                                dob: data.dob === "" ? moment(new Date()).format('YYYY-MM-DD') : moment(data.dob).format('YYYY-MM-DD'),
                                'anniversary_date': submit.anniversary_date === "" ? new Date() : new Date(submit.anniversary_date),
                                'customer_address': submit.customer_address,
                                'flat_villa_no': submit.flat_villa_no,
                                'floor': submit.floor,
                                'landmark': submit.landmark,
                                'location': submit.location,
                                'pincode': submit.pincode,
                                'gender': submit.gender,
                                'platform': "Website",
                                'delivery_exe_id': deliveryExecutiveID,
                                'wallet_balance': submit.wallet_balance,
                                'credit_limit': submit.credit_limit,
                                'source': submit.source,
                                'status': submit.status,
                                'updated_date': new Date(),
                                
                                'referral_code': "",
                                'latitude': "",
                                'longitude': "",
                                
                            }).then(() => {
                                console.log('Updated Successfully');
                                fetchInitialData();
                                handleSearch()
                                db.collection("customer_activities").add({
                                    'customer_id': customerId,
                                    'customer_name': submit.customer_name,
                                    'customer_phone': submit.customer_phone,
                                    'customer_address': submit.customer_address,
                                    'hub_name': submit.hub_name,
                                    'delivery_exe_id': deliveryExecutiveID,
                                    'user': 'Server',
                                    'object': "Edit Customer",
                                    'action': "Edit Customer",
                                    'description': `Customer details has been updated from CRM by ${loggedIn_user}`,
                                    'platform':"CRM",
                                    'date': new Date(),
                                    'created_date': new Date()
                                });
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })

                                Toast.fire({
                                    icon: 'success',
                                    title: 'Customer Updated Successfully'
                                })
                                closeModal();
                                setLoading(false);
                                setSelectedHub(null);
                                setSelectedLocation([]);
                                setSubmit({ customer_id: '', customer_image: "", customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', alt_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', platform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() })
                            })
                        })
                            .catch(error => {
                                console.error("Error getting download URL: ", error);
                            });
                    }
                );
            } else {
                db.collection("customers_data").doc(editID).update({
                    'customer_id': submit.customer_id,
                    'customer_image': submit.customer_image,
                    'customer_type': submit.customer_type,
                    'customer_name': submit.customer_name,
                    'customer_category': submit.customer_category === "" ? "Lead" : submit.customer_category,
                    'hub_name': submit.hub_name,
                    'customer_phone': submit.customer_phone,
                    'alt_phone': submit.alt_phone,
                    'customer_email': submit.customer_email,
                    
                    'anniversary_date': submit.anniversary_date === "" ? new Date() : new Date(submit.anniversary_date),
                    'customer_address': submit.customer_address,
                    'flat_villa_no': submit.flat_villa_no,
                    'floor': submit.floor,
                    'landmark': submit.landmark,
                    'location': submit.location,
                    'pincode': submit.pincode,
                    'gender': submit.gender,
                    'platform': "Website",
                    'delivery_exe_id': deliveryExecutiveID,
                    'wallet_balance': submit.wallet_balance,
                    'credit_limit': submit.credit_limit,
                    'source': submit.source,
                    'status': submit.status,
                    'updated_date': new Date(),
                    
                    'referral_code': "",
                    'latitude': "",
                    'longitude': "",
                    

                }).then(() => {
                    fetchInitialData();
                    handleSearch();
                    console.log('Updated Successfully');
                    db.collection("customer_activities").add({
                        'customer_id': customerId,
                        'customer_name': submit.customer_name,
                        'customer_phone': submit.customer_phone,
                        'customer_address': submit.customer_address,
                        'hub_name': submit.hub_name,
                        'delivery_exe_id': deliveryExecutiveID,
                        'user': 'Server',
                        'object': "Edit Customer",
                        'action': "Edit Customer",
                        'description': `Customer details has been updated from CRM by ${loggedIn_user}`,
                        'platform':"CRM",
                        'date': new Date(),
                        'created_date': new Date()
                    });
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    Toast.fire({
                        icon: 'success',
                        title: 'Customer Updated Successfully'
                    })
                    closeModal();
                    setLoading(false);
                    setSelectedHub(null);
                    setSelectedLocation([]);
                    setSubmit({ customer_id: '', customer_image: '', customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', alt_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', platform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() })
                })
            }



        } else {
            setLoading(true);
            const phoneExists = await checkPhoneExists(submit.customer_phone);
            if (phoneExists) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Phone no already exists in the database!',
                });
                setLoading(false);
                return;
            }


            if (submit.customer_image) {
                const storageRef = ref(storage, `users/${submit.customer_image.name}`);
                const uploadTask = uploadBytesResumable(storageRef, submit.customer_image);

                uploadTask.on(
                    "state_changed",
                    snapshot => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    },
                    error => {
                        console.error("Error uploading image: ", error);
                    },
                    () => {
                        
                        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                            
                            await db.collection("customers_data").add({
                                'customer_id': customerId,
                                'customer_image': downloadURL,
                                'customer_type': submit.customer_type,
                                'customer_name': submit.customer_name,
                                'customer_category': submit.customer_category === "" ? "Lead" : submit.customer_category,
                                'hub_name': submit.hub_name,
                                'customer_phone': submit.customer_phone,
                                'alt_phone': submit.alt_phone,
                                'customer_email': submit.customer_email,
                                
                                'anniversary_date': submit.anniversary_date === "" ? new Date() : new Date(submit.anniversary_date),
                                'customer_address': submit.customer_address,
                                'flat_villa_no': submit.flat_villa_no,
                                'floor': submit.floor,
                                'landmark': submit.landmark,
                                'location': submit.location,
                                'pincode': submit.pincode,
                                'gender': submit.gender,
                                'platform': "Website",
                                'delivery_exe_id': deliveryExecutiveID,
                                'wallet_balance': 0,
                                'credit_limit': 110,
                                'source': submit.source,
                                'status': "1",
                                'updated_date': new Date(),
                                'created_date': new Date(),
                                'referral_code': "",
                                'latitude': "",
                                'longitude': "",
                                'registered_date': new Date(),
                            });
                            reset();


                        })
                            .then(() => {
                                
                                console.log('Customer Added Successfully');
                                
                                
                                db.collection("customer_activities").add({
                                    'customer_id': customerId,
                                    'customer_name': submit.customer_name,
                                    'customer_phone': submit.customer_phone,
                                    'customer_address': submit.customer_address,
                                    'hub_name': submit.hub_name,
                                    'delivery_exe_id': deliveryExecutiveID,
                                    'user': 'Server',
                                    'object': "Add Customer",
                                    'action': "Add Customer",
                                    'description': `Customer has been created from backend by ${loggedIn_user}`,
                                    'platform':"CRM",
                                    'date': new Date(),
                                    'created_date': new Date()
                                })
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast_1) => {
                                        toast_1.addEventListener('mouseenter', Swal.stopTimer);
                                        toast_1.addEventListener('mouseleave', Swal.resumeTimer);
                                    }
                                });

                                closeModal();
                                setLoading(false);
                                setSubmit({ customer_id: '', customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', alt_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', platform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() });
                                setSelectedHub(null);
                                setSelectedLocation([]);
                                

                            })
                            .catch(error => {
                                console.error("Error adding user: ", error);
                            });
                    }
                );
            } else {
                db.collection("customers_data").add({
                    'customer_id': customerId,
                    'customer_type': submit.customer_type,
                    'customer_image': '',
                    'customer_name': submit.customer_name,
                    'customer_category': submit.customer_category === "" ? "Lead" : submit.customer_category,
                    'hub_name': submit.hub_name,
                    'customer_phone': submit.customer_phone,
                    'alt_phone': submit.alt_phone,
                    'customer_email': submit.customer_email,
                    'dob': submit.dob === "" ? new Date() : new Date(submit.dob),
                    'anniversary_date': submit.anniversary_date === "" ? new Date() : new Date(submit.anniversary_date),
                    'customer_address': submit.customer_address,
                    'flat_villa_no': submit.flat_villa_no,
                    'floor': submit.floor,
                    'landmark': submit.landmark,
                    'location': submit.location,
                    'pincode': submit.pincode,
                    'gender': submit.gender,
                    'platform': "Website",
                    'delivery_exe_id': deliveryExecutiveID,
                    'wallet_balance': 0,
                    'credit_limit': 110,
                    'source': submit.source,
                    'status': "1",
                    'updated_date': new Date(),
                    'created_date': new Date(),
                    'referral_code': "",
                    'latitude': "",
                    'longitude': "",
                    'registered_date': new Date(),
                }).then(() => {
                    reset();
                    
                    console.log('Customer Added Successfully');
                    
                    
                    db.collection("customer_activities").add({
                        'customer_id': customerId,
                        'customer_name': submit.customer_name,
                        'customer_phone': submit.customer_phone,
                        'customer_address': submit.customer_address,
                        'hub_name': submit.hub_name,
                        'delivery_exe_id': deliveryExecutiveID,
                        'user': 'Server',
                        'object': "Add Customer",
                        'action': "Add Customer",
                        'description': `Customer has been created from crm by  ${loggedIn_user}`,
                        'platform':"CRM",
                        'date': new Date(),
                        'created_date': new Date()
                    })
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    closeModal();
                    setLoading(false);

                    setSubmit({ customer_id: '', customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', alt_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', platform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() })
                    setSelectedHub(null);
                    setSelectedLocation([]);
                    Toast.fire({
                        icon: 'success',
                        title: 'Customer Added'
                    })
                })
            }

        }


    }

    const deleteData = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("customers_data").doc(id).delete().then(() => {
                    console.log('Deleted Successfully');
                    setSubmit({ customer_id: '', customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', platform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() })
                    closeModal();
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })

            }
        })

    }


    useEffect(() => {
        const getCurrentTimeOfDay = () => {
            const currentTime = new Date().getHours();
            if (currentTime < 12) {
                setTimeOfDay('Good Morning');
            } else if (currentTime >= 12 && currentTime < 18) {
                setTimeOfDay('Good Afternoon');
            } else {
                setTimeOfDay('Good Evening');
            }
        };

        getCurrentTimeOfDay();
    }, []);
    const rolePermission = () => {
        const Toast = Swal.mixin({
            toast: true,
            background: '#d7e7e6',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
        });

        Toast.fire({
            icon: 'error',
            title: 'You are not authorised to do this action'
        });

    }
    return (
        <>
            {loading && ( 
                <div className="loader-overlay">
                    <div className="">
                        <img style={{
                            height: "6rem"
                        }} src="images/loader.gif"></img>
                    </div>
                </div>
            )}
            <div class="container-scroller">

                <TopPanel />

                <div class="container-fluid page-body-wrapper">
                    <Sidebar />

                    <div class="main-panel">
                        <div class="content-wrapper">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="home-tab">
                                        <div class="d-sm-flex align-items-center justify-content-between border-bottom">
                                            <ul class="nav nav-tabs" role="tablist">

                                            </ul>
                                            <div>

                                                <div class="btn-wrapper">
                                                    {permissible_roles.includes('add_customers') ? <><a href="#" class="btn btn-primary text-white me-0 mr-2" onClick={() => addNew()}><i class="icon-add"></i>Add Customer</a></> : <><a href="#" class="btn btn-primary text-white me-0 mr-2" onClick={() => rolePermission()}><i class="icon-add"></i>Add Customer</a></>}

                                                    <a href="javascript:void(0)" class="btn btn-primary text-white me-0" style={{ marginLeft: "1rem" }}  onClick={getCustomerPage}><i class="icon-download"></i>Export Data</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style={{ marginTop: "1rem", }}>
                                <div class="col-md-4 grid-margin">
                                    <div class="card d-flex align-items-start" style={{ background: '#84bf93' }}>
                                        <div class="card-body" >
                                            <div class="d-flex flex-row align-items-start">
                                                <i class="mdi mdi-account-check icon-lg" style={{ color: '#fff' }}></i>
                                                <div class="ms-3">
                                                    <h6 class="text-user" style={{ fontSize: "22px", fontWeight: "800" ,color:'#fff' }}>{newUserCount} </h6>
                                                    <p class="mt-2 text-muted card-text custom-text">New Registrations</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 grid-margin">
                                    <div class="card d-flex align-items-start" style={{ background: '#4a54ba' }}>
                                        <div class="card-body">
                                            <div class="d-flex flex-row align-items-start">
                                                <i class="mdi mdi-account-multiple-outline icon-lg" style={{ color: '#fff' }}></i>
                                                {/* <i class="mdi mdi-account-multiple-outline menu-icon"></i> */}
                                                <div class="ms-3">
                                                    <h6 class="text-users" style={{ fontSize: "22px", fontWeight: "800", color: "#fff" }}>{collectionSize} </h6>
                                                    <p class="mt-2 text-muted card-text custom-text" style={{ fontSize: "15px", }}>Total Customers</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 grid-margin">
                                    <div class="card d-flex align-items-start" style={{ background: '#4a54ba' }}>
                                    <div class="card-body">
        <div class="d-flex flex-row align-items-start">
            <i class="mdi mdi-account-search icon-lg" style={{ color: '#fff' }}></i>
            <div class="ms-3" style={{ display: 'flex' }}>
                <input
                    type="search"
                    className="form-control"
                    onChange={handleInputChange} // Handle input change and trigger search
                    value={searchQuery}
                    placeholder="Search Customer here"
                    title="Search here"
                    onKeyDown={handleKeyDown} // Handle 'Enter' key press for search
                />
                <a
                    href="#"
                    class="btn btn-xs btn-primary text-white me-0"
                    onClick={handleSearch} // Trigger search on button click
                    style={{ marginLeft: '10px', background: 'rgb(132, 191, 147)' }}
                >
                    <i class="icon-search"></i>
                </a>
            </div>
        </div>
    </div>


                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 grid-margin stretch-card">
                                <div class="card">
                                    <div class="card-body">
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <h4 class="card-title">Customers</h4>
                                            <p class="card-description">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    selectsStart
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    dateFormat="DD/MM/YYYY"
                                                    placeholderText="From reg"
                                                    className="form-control"
                                                />
                                                <span className="" style={{ width: "1rem" }}> TO  </span>
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    selectsEnd
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    minDate={startDate}
                                                    dateFormat="DD/MM/YYYY"
                                                    placeholderText="To reg"
                                                    className="form-control"
                                                />
                                            </p>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="home-tab">
                                                <div class="d-sm-flex align-items-center justify-content-between border-bottom">
                                                    <ul class="nav nav-tabs" role="tablist">
                                                        <li class="nav-item">
                                                            <a class="nav-link active ps-0" id="home-tab" data-bs-toggle="tab" href="#overview" role="tab"
                                                                aria-controls="overview" aria-selected="true" onClick={() => filterData("All")}>All</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link" id="profile-tab" data-bs-toggle="tab" href="#audiences" role="tab"
                                                                aria-selected="false" onClick={() => filterData("One Time")}>One time</a>
                                                        </li>
                                                       
                                                        <li class="nav-item">
                                                            <a class="nav-link" id="contact-tab" data-bs-toggle="tab" href="#demographics" role="tab"
                                                                aria-selected="false" onClick={() => filterData("Subscription")}>Subscribed</a>
                                                        </li>
                                                        
                                                        <li class="nav-item">
                                                            <a class="nav-link" id="contact-tab" data-bs-toggle="tab" href="#demographics" role="tab"
                                                                aria-selected="false" onClick={() => filterData("Lead")}>Lead</a>
                                                        </li>
                                            
                                                    </ul>
                                                    <div>

                                                        <div class="btn-wrapper">
                                                            <a href="#" class="btn btn-primary text-white me-0" onClick={handleDateRange}><i class="icon-search"></i>Search</a>
                                                            <a href="#" class="btn btn-primary text-white me-0" onClick={handleReset}><i class="icon-reset"></i>reset</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            ID
                                                        </th>
                                                        <th>
                                                            Hub
                                                        </th>
                                                        <th>
                                                            Customer Name
                                                        </th>
                                                        <th>
                                                            Customer's Contact
                                                        </th>
                                                        <th>
                                                            Source
                                                        </th>
                                                        <th>
                                                            Wallet Amount
                                                        </th>
                                                        <th>
                                                            Credit Limit
                                                        </th>

                                                        <th>
                                                            Alternate Number
                                                        </th>

                                                        <th>
                                                            Registered On
                                                        </th>
                                                        <th>
                                                            Account Status
                                                        </th>

                                                        <th>
                                                            Action
                                                        </th>

                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    {currentItems.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="10" style={{ textAlign: "center" }}>No data found</td>
                                                        </tr>
                                                    ) : (
                                                        currentItems.map(({ id, data }, index) => {
                                                            const itemIndex = indexOfFirstItem + index + 1;
                                                            return (
                                                                
                                                                <tr key={id} onClick={() => permissible_roles.includes('view_customers') ? navi(data.customer_id) : rolePermission()} style={{ cursor: "pointer" }}>

                                                                    <td>
                                                                        {data.customer_id}
                                                                    </td>
                                                                    <td>
                                                                        {data.hub_name}
                                                                    </td>
                                                                    <td>
                                                                        <p>{data.customer_name}</p>

                                                                    </td>
                                                                    <td>
                                                                        {data.customer_phone}
                                                                    </td>
                                                                    <td>
                                                                        {data.source}
                                                                    </td>
                                                                    <td>
                                                                        ₹ {data.wallet_balance}
                                                                    </td>
                                                                    <td>
                                                                        ₹ {data.credit_limit}
                                                                    </td>
                                                                    <td>
                                                                        {data.alt_phone}
                                                                    </td>
                                                                    <td>
                                                                    {(() => {
    try {
        return moment(data.registered_date.toDate().toISOString()).format("DD-MM-YYYY, h:mm a");
    } catch (error) {
        console.error("Error parsing registered_date:", error);
        return "Invalid Date";
    }
})()}

                                                                    </td>
                                                                    <td>
                                                                        {data.status === "1" ? "Active" : "Inactive"}
                                                                    </td>
                                                                    <td>
                                                                        {/* <Link to={`/profile/${data.customer_phone}`} style={{ color: "black" }}><button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} class="btn btn-dark btn-sm"><i class="menu-icon mdi mdi-eye" style={{ color: "white" }}></i></button></Link> */}

                                                                        <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={(e) => changeStatusForm(e, data, id)} class="btn btn-dark btn-sm"><i class="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i></button>
                                                                    </td>
                                                                </tr>



                                                            );
                                                        })
                                                    )}
                                                </tbody>

                                            </table>
                                        </div>
                                        {/* <button onClick={fetchMoreData} disabled={loading}>Load More</button> */}
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <button onClick={() => paginate(currentPage - 1)} className="page-link" disabled={currentPage === 1}>Previous</button>
                                            </li>
                                            {renderPageButtons()}
                                            <li className="page-item">
                                                <button onClick={() => paginate(currentPage + 1)} className="page-link" disabled={currentPage === totalPages}>Next</button>
                                            </li>
                                            <li className="page-item">
                                                <button onClick={fetchMoreData} disabled={loading} className="page-link" >Load More</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <Footer />

                    </div>

                </div>

            </div>

            <div class="modal fade" id="exampleModal-2" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel-2">Add Customer</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form class="forms-sample" onSubmit={handleSubmit}>
                                <div class="form-group row">
                                    <div class="col">
                                        <label>User Image:</label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ padding: "10px" }}
                                            name="customer_image"
                                            
                                            onChange={handleChange}

                                        />
                                    </div>
                                    <div class="col">
                                        <label >Customer Category</label>
                                        <Select
                                            options={categoryList}
                                            onChange={handleCategoryChange}
                                            value={selectedCategory}
                                            placeholder="Select Category"
                                        />

                                    </div>
                                    <div class="col">
                                        <label >Hubs *</label>
                                        <Select
                                            options={hubNames}
                                            onChange={handleHubChange}
                                            value={selectedHub}
                                            placeholder="Select Hub Name"
                                            required
                                        />

                                    </div>

                                </div>
                                <div class="form-group row">
                                    <div class="col">
                                        <label for="exampleSelectGender">Customer Phone No *</label>
                                        <input class="form-control" type="tel" pattern="[0-9]*" onChange={handleChange} id="customer_phone" name="customer_phone" value={submit.customer_phone} maxLength={10} required autoComplete="off" />

                                    </div>
                                    <div class="col">
                                        <label for="exampleSelectGender">Alternate Phone No</label>
                                        <input class="form-control" type="tel" pattern="[0-9]*" onChange={handleChange} id="alt_phone" name="alt_phone" value={submit.alt_phone} maxLength={10} autoComplete="off" />

                                    </div>

                                    <div class="col">
                                        <label for="exampleSelectGender">Customer Name *</label>
                                        <input type="text" class="form-control" onChange={handleChange} id="customer_name" name="customer_name" value={submit.customer_name} required autoComplete="off" />

                                    </div>

                                </div>
                                <div class="form-group row">
                                    <div class="col">
                                        <label for="exampleSelectGender">Customer Email</label>
                                        <input type="email" class="form-control" onChange={handleChange} id="customer_email" name="customer_email" value={submit.customer_email} autoComplete="off" />

                                    </div>
                                    <div class="col">
                                        <label for="exampleSelectGender">DOB</label>
                                        <input type="date" style={{
                                            
                                            height: "35px",
                                            padding: "12px 20px",
                                            boxSizing: "border-box",
                                            
                                            borderRadius: "4px",
                                            
                                            
                                            resize: "none"
                                        }} id="dob" name="dob" onChange={handleChange} value={submit.dob} class="form-control" />

                                    </div>
                                    <div class="col">
                                        <label >Anniversary Date</label>
                                        <input type="date" style={{
                                            height: "35px",
                                            padding: "12px 20px",
                                            boxSizing: "border-box",
                                            borderRadius: "4px",
                                            resize: "none"
                                        }} id="anniversary_date" name="anniversary_date" onChange={handleChange} value={submit.anniversary_date} class="form-control" />
                                    </div>


                                </div>
                                <div class="form-group row">
                                    <div class="col">
                                        <label >Customer Address *</label>
                                        <input type="text" class="form-control" onChange={handleChange} id="customer_address" name="customer_address" value={submit.customer_address} required autoComplete="off" />
                                    </div>
                                    <div class="col">
                                        <label >Flat/villa No</label>
                                        <input type="text" class="form-control" onChange={handleChange} id="flat_villa_no" name="flat_villa_no" value={submit.flat_villa_no} autoComplete="off" />
                                    </div>
                                    <div class="col">
                                        <label >Floor</label>
                                        <input type="text" class="form-control" onChange={handleChange} id="floor" name="floor" value={submit.floor} autoComplete="off" />

                                    </div>


                                </div>

                                <div class="form-group row">
                                    <div class="col">
                                        <label >Landmark</label>
                                        <input type="text" class="form-control" onChange={handleChange} id="landmark" name="landmark" value={submit.landmark} autoComplete="off" />
                                    </div>
                                    <div class="col">
                                        <label >Location *</label>
                                        <Select
                                            options={locationOptions}
                                            onChange={handleLocationChange}
                                            value={selectedLocation}
                                            placeholder="Select Location"
                                            required
                                        />
                                        <p style={{ color: "red" }}>{deliveryExecutiveID === "notfound" ? <>**No Delivery agent found for this location...</> : <></>} </p>
                                    </div>

                                    <div class="col">
                                        <label >Pincode</label>
                                        <input class="form-control" type="tel" pattern="[0-9]*" onChange={handleChange} id="pincode" name="pincode" value={submit.pincode} maxLength={6} autoComplete="off" />

                                    </div>


                                </div>

                                <div class="form-group row">




                                    <div className="col">
                                        <div style={{ display: 'inline-block', marginRight: '10px' }}>
                                            <label>Gender *</label>
                                        </div>
                                        <br />
                                        <div style={{ display: 'inline-block' }}>
                                            <input type="hidden" id="genderHidden" name="gender" required />
                                            <label>
                                                <input
                                                    type="radio"
                                                    id="genderMale"
                                                    value="male"
                                                    name="gender"
                                                    checked={submit.gender === "male"}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <span style={{ marginRight: '10px', marginLeft: "10px" }}>Male</span>
                                            </label>
                                            <label style={{ marginRight: '10px' }}>
                                                <input
                                                    type="radio"
                                                    id="genderFemale"
                                                    value="female"
                                                    name="gender"
                                                    checked={submit.gender === "female"}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <span style={{ marginRight: '10px', marginLeft: "10px" }}>Female</span>
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    id="genderOther"
                                                    value="other"
                                                    name="gender"
                                                    checked={submit.gender === "other"}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <span style={{ marginRight: '10px', marginLeft: "10px" }}>Other</span>
                                            </label>
                                        </div>

                                    </div>




                                    <div class="col">
                                        <label >Customer Type</label>
                                        <br />
                                        <input
                                            type="checkbox"
                                            id="customer_type"
                                            name="customer_type"
                                            checked={submit.customer_type} 
                                            onChange={(e) => setSubmit({ ...submit, customer_type: e.target.checked })} 
                                        />
                                        <label htmlFor="customer_type" style={{ marginLeft: '0.5rem' }}>Corporate</label>
                                    </div>

                                    <div class="col">
                                        <label >Source</label>
                                        <Select
                                            options={sourceList}
                                            onChange={handleSourceChange}
                                            value={sourceCategory}
                                            placeholder="Select Category"
                                        />

                                    </div>
                                </div>
                                <div class="modal-footer">
                                    {permissible_roles.includes('edit_customers') ? <>{nod === "" ? <></> : <><button type="submit" value="submit" class="btn btn-success">Submit</button></>} </> : <>{nod === "" ? <></> : <><button onClick={() => rolePermission()} class="btn btn-success">Submit</button></>}</>}

                                    <button type="button" class="btn btn-info" data-bs-dismiss="modal">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Customers