import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import db from "./Firebase";
import { DateTimeUtil } from "./Utility";
import { Timestamp } from "firebase/firestore";
import "./vactions.css";

import GlobalContext from "./context/GlobalContext";
import { handleLogout } from "./Utility";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
const CustomerVacations = () => {
  
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [events, setEvents] = useState([]);

  const { permissible_roles } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    if (!loggedIn) {
      navigate("/login");
    } else {
      if (permissible_roles.length > 0) {
        if (!permissible_roles.includes("subscription_report")) {
          handleLogout();
          navigate("/permission_denied");
        }
      }
    }
  }, [navigate, permissible_roles]);

  
  
  const handleRowClick = (customer_id) => {
    window.open(`/profile/${customer_id}`, "_blank");
  };

  async function fetchVacations() {
    if (!startDate || !endDate) {
      alert("Please select both start and end dates.");
      return;
    }
    console.log(startDate, endDate);
  
    const start_date = new Date(startDate);
    console.log("Start Date:", start_date);
    const end_date = new Date(endDate);
    console.log("End Date:", end_date);
  
    try {
      const vacationDocs = await db.collection("customers_vacation").get();
      const vacationDataArray = [];
      
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Ensure we compare only the date part
      // Iterating over the documents
      for (const doc of vacationDocs.docs) {
        try {
          const vacationData = doc.data();
          const vacationStartDate1 = vacationData.start_date.toDate(); // Converting to JavaScript Date
          const vacationEndDate1 = vacationData.end_date.toDate(); // Converting to JavaScript Date
          // Converting Firestore Timestamp to Date object
          const vacationStartDate = vacationData.start_date.toDate(); // Converting to JavaScript Date
          const vacationEndDate = vacationData.end_date.toDate(); // Converting to JavaScript Date

           // Setting status dynamically
          
           const vacationStatus = today > vacationEndDate ? "Inactive" : "Active";
           


           vacationStartDate.setHours(0, 0, 0, 0); // Set hours for date comparison
           vacationEndDate.setHours(23, 59, 59, 999); // Ensure end date includes all hours
          // Check if the given date range is within the vacation period

          if (
            vacationStartDate <= start_date &&
            vacationEndDate >= end_date &&
            (statusFilter === "" || vacationStatus === statusFilter)
          ) {
            vacationDataArray.push({
              customer_id: vacationData.customer_id,
              user: vacationData.user,
              customer_name: vacationData.customer_name,
              customer_phone: vacationData.customer_phone,
              start_date: vacationStartDate1,
              end_date: vacationEndDate1,
              status: vacationStatus,
              updated_by: vacationData.updated_by,
              updated_date: vacationData.updated_date || "",
            });
          }

        } catch (error) {
          console.log(`Error processing vacation record ${doc.id}: ${error}`);
        }
      }
  
      if (vacationDataArray.length === 0) {
        alert("No vacations found for the selected range.");
      }
  
      // Return the formatted vacation data as an array
      console.log(vacationDataArray)
      setEvents(vacationDataArray)
      return vacationDataArray;
    } catch (error) {
      console.log('Error fetching vacation data:', error);
      return [];  // Return an empty array if an error occurs
    }
  }
  
  const exportToCSV = () => {
    if (events.length === 0) {
      alert("No data available to export.");
      return;
    }
  
    const headers = [
      "Customer ID",
      "Created By",
      "Name",
      "Phone",
      "Start Date",
      "Start Time",
      "End Date",
      "End Time",
      "Status",
      "Updated By",
      "Updated Date",
    ];
  
    const rows = events.map((event) => [
      event.customer_id ||"-",
      event.user || "-",
      event.customer_name || "-",
      event.customer_phone || "-",
      DateTimeUtil.timestampToDate(new Date(event.start_date)) || "-",
      DateTimeUtil.timestampToTimeAMPM(new Date(event.start_date)) || "-",
      DateTimeUtil.timestampToDate(new Date(event.end_date)) || "-",
      DateTimeUtil.timestampToTimeAMPM(new Date(event.end_date)) || "-",
      event.status || "-",
      event.updated_by ||"-",,
      event.updated_date
        ? DateTimeUtil.timestampToDate(new Date(event.updated_date.seconds * 1000)) + " " + DateTimeUtil.timestampToTimeAMPM(new Date(event.updated_date.seconds * 1000))
        : "-",,
    ]);
  
    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.map((value) => `"${value}"`).join(",")),
    ].join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "vacation_events.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  const exportToPDF = () => {
    if (events.length === 0) {
      alert("No data available to export.");
      return;
    }
  
    const doc = new jsPDF();
    doc.text("Vacation Report", 14, 10);
  
    const tableColumns = [
      "Customer ID",
      "Created By",
      "Customer Name",
      "Customer Phone",
      "Start Date",
      "Start Time",
      "End Date",
      "End Time",
      "Status",
      "Updated By",
      "Updated Date",
    ];
  
    const tableRows = events.map((event) => [
      event.customer_id || "-",
      event.user || "-",
      event.customer_name || "-",
      event.customer_phone || "-",
      DateTimeUtil.timestampToDate(new Date(event.start_date)) || "-",
      DateTimeUtil.timestampToTimeAMPM(new Date(event.start_date)) || "-",
      DateTimeUtil.timestampToDate(new Date(event.end_date)) || "-",
      DateTimeUtil.timestampToTimeAMPM(new Date(event.end_date)) || "-",
      event.status ||"-",
      event.updated_by || "-",
      event.updated_date
        ? DateTimeUtil.timestampToDate(new Date(event.updated_date.seconds * 1000)) + " " + DateTimeUtil.timestampToTimeAMPM(new Date(event.updated_date.seconds * 1000))
        :"-",
    ]);
  
    doc.autoTable({
      head: [tableColumns],
      body: tableRows,
      startY: 20,
      margin: { left: 14, right: 14 },
      styles: { fontSize: 10 },
    });
  
    doc.save("vacation_report.pdf");
  };
  

  return (
    <>
     <div className="container-scroller">
  <div className="container-fluid">
    <div className="main-panel-vaca" style={{ width: "100%", minHeight: "calc(52vh - 97px)!important" }}>
      <div className="panel" style={{ padding: "20px", backgroundColor: "#f8f9fa", borderRadius: "8px" }}>
        <div className="panel" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>
            VACATION REPORT
          </span>
          <div style={{ display: "flex", gap: "10px" }}>
            {events.length > 0 && (
              <button onClick={exportToCSV} style={{ marginLeft: "10px" }} className="button-cls">
                Export to CSV
              </button>
            )}
            {events.length > 0 && (
              <button onClick={exportToPDF} className="button-cls">Export to PDF</button>
            )}
          </div>
        </div>
      </div>
      <br />
      <div style={{ display: "flex", gap: "20px", width: "100%", flexWrap: "wrap" }}>
        {/* Start Date */}
        <div style={{ flex: "1", minWidth: "200px" }} className="date-fix">
          <label>Start Date:</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="dd/MM/yyyy"
            className="form-control"
            placeholderText="Select start date"
          />
        </div>

        {/* End Date */}
        <div style={{ flex: "1", minWidth: "200px" }} className="date-fix">
          <label>End Date:</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="dd/MM/yyyy"
            className="form-control"
            placeholderText="Select end date"
          />
        </div>

        {/* Status */}
        <div className="date-fix" style={{ flex: "1", minWidth: "160px",marginTop: "8px",  marginRight:"87PX"}}>
        <label>Status:</label>
          <select
          className="status"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            style={{ padding: "5px",
              marginBottom: "10px",
              width: "206px",
              borderRadius: "5px" }}
          >
            <option value="">All</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        </div>

        {/* Search Button */}
        <div style={{ marginTop:"27PX" }}>
          <button onClick={fetchVacations} className="button-cls vaction-s">Search Vacations</button>
        </div>
      </div>
    </div>

    <div className="result" style={{ height: "500px", overflowY: 'auto', overflowX: 'auto' }}>
      <table className="table table-striped" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Customer ID</th>
            <th>Created by</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            <th>Updated By</th>
            <th>Updated Date</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event, index) => (
            <tr key={index} className="hover-highlight" style={{ cursor: "pointer" }} onClick={() => handleRowClick(event.customer_id)}>
              <td>{event.customer_id || "N/A"}</td>
              <td>{event.user || "-"}</td>
              <td>{event.customer_name || "N/A"}</td>
              <td>{event.customer_phone || "N/A"}</td>
              <td>
                {DateTimeUtil.timestampToDate(new Date(event.start_date)) + " " + DateTimeUtil.timestampToTimeAMPM(new Date(event.start_date)) || "N/A"}
              </td>
              <td>
                {DateTimeUtil.timestampToDate(new Date(event.end_date)) + " " + DateTimeUtil.timestampToTimeAMPM(new Date(event.end_date)) || "N/A"}
              </td>
              <td style={{ color: event.status === "Active" ? "green" : "red", fontWeight: "bold" }}>
                {event.status || "-"}
              </td>
              <td>{event.updated_by || "-"}</td>
              <td>
                {DateTimeUtil.timestampToDate(new Date(event.updated_date.seconds * 1000)) + " " + DateTimeUtil.timestampToTimeAMPM(new Date(event.updated_date.seconds * 1000)) || "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
</div>

    </>
  );
};

// Helper function to get start and end timestamps for a date
function getStartAndEndTimestamps(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    console.error("Invalid Date:", dateString);
    return { start: null, end: null };
  }
  const startOfDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  ).getTime();
  const endOfDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59,
    999
  ).getTime();
  return {
    start: startOfDay,
    end: endOfDay,
  };
}

export default CustomerVacations;
