import React, { useState , useEffect,useContext } from "react";
import db from "./Firebase";
import { Button} from "react-bootstrap";
import "./Report.css";
import { useNavigate } from 'react-router-dom';
import ExportTableToExcel from "./ExportTableToExcel";
import Moment from "moment";
import { extendMoment } from "moment-range";
import DatePicker from "react-datepicker";
import { collection, getDocs, query, where, getCountFromServer, updateDoc ,addDoc,serverTimestamp} from 'firebase/firestore';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import { getUserInfo,handleLogout } from "./Utility";
import GlobalContext from "./context/GlobalContext";
import 'jspdf-autotable';
function LowCreditReport() {
    const {permissible_roles} = useContext(GlobalContext);
    const navigate = useNavigate();
    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        if (!loggedIn) {
            navigate("/login");
        }else{
            if(permissible_roles.length>0){
                if(!permissible_roles.includes('low_credit_report')){
                    handleLogout()
                    navigate("/permission_denied");
                }
            }
        }
    }, [navigate,permissible_roles]);

    const { loggedIn, userId, username, loggedIn_user } = getUserInfo();
    const [lowCreditReport, setLowCreditReport] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [initialValue, setInitialvalue] = useState("");
    const [fromDate, setFromDate] = useState('');
    const [totalCreditRequired, setTotalCreditRequired] = useState(0);
    const [totalOrdersAmount, setTotalOrdersAmount] = useState(0);
    const [vacationsData , setVacationsData] = useState([]);
    const [calendarData , setCalendarData] = useState([]);
    const [initalSubsData , setInitialSubsData] = useState([]);
    const [updating , setUpdating] = useState(false);
    const [loadSearch , setLoadSearch] = useState(true);
    const [loading, setLoading] = useState(false);
    

    const moment = extendMoment(Moment);

    const fetchCustomersOnVaction = async () => {
        const date = new Date();
        const tomorrow = new Date(date);
        tomorrow.setDate(tomorrow.getDate() +1);

        const vacation_date = new Date(tomorrow);
        vacation_date.setHours(0,0,0,0);
        const endDate = new Date(vacation_date);
        endDate.setHours(23,59,59,999);

        const vacations_snap = await db.collection("customers_vacation")
                                .where('start_date' , '<=' , endDate)
                                .where('end_date' , '>=' , vacation_date)
                                .get();

        const vacations_data = vacations_snap.docs.map(doc => doc.data());

        console.log("vacations data =>", vacations_data);

        setVacationsData(vacations_data);
        setLoadSearch(false);

    }

    const fetchCustomersWithCalendar = async () => {
        const date = new Date();
        const tomorrow = new Date(date);
        tomorrow.setDate(tomorrow.getDate() +1);

        const calendar_snap = await db.collection("bulk_update_quantity")
                                .where('delivery_date' , '==' , moment(tomorrow).format('YYYY-MM-DD'))
                                .get();

        const calendar_data = calendar_snap.docs.map(doc => doc.data());

        console.log("calendar data =>", calendar_data);

        setCalendarData(calendar_data);

    }

    const fetchBulkQty = async () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        const bulk_calendar_snap = await db.collection('bulk_update_quantity')
            .where('delivery_date', '==', moment(tomorrow).format('YYYY-MM-DD'))
            .get();
        
        const calendar_data = bulk_calendar_snap.docs.map(doc => doc.data());
        const newMap = new Map();
        calendar_data.forEach(data => {
            const value = data.quantity;
            newMap.set(data.subscription_id, value);
        })
        console.log("New Map => " , newMap);
        //setCalendarMap(newMap);
        return newMap;
    }

    const fetchSubscriptions = async () => {
        const date = new Date();
        const tomorrow = new Date(date);
        tomorrow.setDate(tomorrow.getDate() +1);

        const initial_sub_snap = await db.collection("subscriptions_data")
                                .where('next_delivery_date' , '==' , moment(tomorrow).format('YYYY-MM-DD'))
                                .get();

        const initial_sub_data = initial_sub_snap.docs.map(doc => doc.data());

        console.log("subscriptions data =>", initial_sub_data);

        
        const vacationCustomerIds = new Set(vacationsData.map(vacation => vacation.customer_id));
        const filteredData = initial_sub_data.filter(sub => !vacationCustomerIds.has(sub.customer_id));
        console.log("filtered subscriptions data =>", filteredData);
        setInitialSubsData(filteredData);

    }
    useEffect(() => {
        setLoadSearch(true);
        const date = new Date();
        const tomorrow = new Date(date);
        tomorrow.setDate(tomorrow.getDate() +1);
        setFromDate(tomorrow);
        fetchCustomersOnVaction();
        //fetchCustomersWithCalendar();
        //fetchSubscriptions();
    }, []);

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const fetchCashCollectionCount = async (customer_id, date , amount) => {
        const collRef = collection(db, 'cash_collection');
        const q = query(collRef, where('customer_id', '==', customer_id), where('date', '==', date) , where('amount' , amount));
        const snapshot = await getCountFromServer(q);
        return snapshot.data().count;
    };
    const updateCustomerCreditLimits = async () => {
        setUpdating(true);    
        Swal.fire({
            title: 'Are you sure you want to assign the credit to all customers?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Update All!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setLoading(true);
                    for (const customer of lowCreditReport) {
                        const customerId = customer.customer_id;
                        const newCreditLimit = customer.requiredBalance;
    
                        // Query the customers_data collection
                        const q = query(collection(db, 'customers_data'), where('customer_id', '==', customerId));
                        const querySnapshot = await getDocs(q);
    
                        // Update the credit_limit for each matching document
                        querySnapshot.forEach(async (docSnapshot) => {
                            const customerDocRef = docSnapshot.ref;
                            await updateDoc(customerDocRef, {
                                'credit_limit': parseInt(newCreditLimit)
                            });
                            console.log(`Updated credit limit for customer ${customerId} to ${newCreditLimit}`);
    
                            // Record the activity in customer_activities collection
                            await addDoc(collection(db, 'customer_activities'), {
                                customer_id: customerId,
                                user: loggedIn, 
                                description: `Credit limit updated to ${newCreditLimit}  this into ${loggedIn_user}`,
                              // Ensure loggedIn_user is properly retrieved
                                created_date: serverTimestamp(),
                            });
                        });
                    }
                    setUpdating(false);
                    
                    handleSearch();
                } catch (error) {
                    console.error("Error updating customer credit limits: ", error);
                }
                setLoading(false);
            } else {
                setUpdating(false);
                setLoading(false);
            }
        });
    };
    
    
    const handleSearch = async () => {
        setShowSpinner(true);
        setDataLoaded(false);
        setInitialvalue("");
        //fetchCashCollectionStatus();
        let bulk_Quantity_map = new Map();
        bulk_Quantity_map = await fetchBulkQty();    
        const nextDeliveryDate = moment(fromDate).format('YYYY-MM-DD');
        const subscriptionMap = new Map();
        const newCustomerDataList = [];
        let totalCreditLimit = 0;
        let totalOrders = 0;

        const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const dayOfWeekIndex = fromDate.getDay();
        const dayOfWeek = weekdays[dayOfWeekIndex];
        //console.log("day of week", dayOfWeek);

        const subscriptionSnapshot = await db.collection('subscriptions_data')
            .where('subscription_type', '!=', 'Custom')
            .where('next_delivery_date', '==', nextDeliveryDate)
            .where('status', '==', '1')
            .get();
        
        const customSubscriptionSnapshot = await db.collection('subscriptions_data')
            .where('subscription_type', '==', 'Custom')
            .where(dayOfWeek, '>=', 1)
            .where('status', '==', '1')
            .get();

        subscriptionSnapshot.docs.forEach(doc => {
            const subscription = doc.data();
            if(!vacationsData.some(vacation => vacation.customer_id === subscription.customer_id)) {
                if (bulk_Quantity_map.has(subscription.subscription_id)) {
                    subscription.quantity = bulk_Quantity_map.get(subscription.subscription_id);
                }
                if (!subscriptionMap.has(subscription.customer_id)) {
                subscriptionMap.set(subscription.customer_id, { items: [], total_price: 0 });
            }
            subscriptionMap.get(subscription.customer_id).items.push(subscription);
            subscriptionMap.get(subscription.customer_id).total_price += (subscription.price * subscription.quantity);
            }
            
        });

        customSubscriptionSnapshot.docs.forEach(doc => {
            const subscription = doc.data();
                if(!vacationsData.some(vacation => vacation.customer_id === subscription.customer_id)) {
                    if (bulk_Quantity_map.has(subscription.subscription_id)) {
                        subscription.quantity = bulk_Quantity_map.get(subscription.subscription_id);
                    }else {
                        subscription.quantity = subscription[dayOfWeek];
                    }
                    if (!subscriptionMap.has(subscription.customer_id)) {
                    subscriptionMap.set(subscription.customer_id, { items: [], total_price: 0 });
                }
                subscriptionMap.get(subscription.customer_id).items.push(subscription);
                subscriptionMap.get(subscription.customer_id).total_price += (subscription.price * subscription.quantity);
                }
        });


        const customerIds = Array.from(subscriptionMap.keys());
        const customerPromises = customerIds.map(customer_id => 
            db.collection('customers_data').where('customer_id', '==', customer_id).get()
        );

        const customerSnapshots = await Promise.all(customerPromises);

        for (let i = 0; i < customerSnapshots.length; i++) {
            const customerSnapshot = customerSnapshots[i];
            const customerData = customerSnapshot.docs.map(doc => doc.data())[0];

            if (customerData) {
                const { customer_id, wallet_balance, credit_limit } = customerData;
                const total_price = subscriptionMap.get(customer_id).total_price;
                let requiredBalance = 0;
                let isBalanceSufficient = true;

                if (wallet_balance < 0) {
                    if(credit_limit < total_price) {
                        isBalanceSufficient = false;
                        requiredBalance = total_price;
                    }
                    
                } else {
                    //isBalanceSufficient = wallet_balance + credit_limit >= total_price;
                    if(wallet_balance + credit_limit < total_price) {
                        isBalanceSufficient=false;
                    }
                    if (!isBalanceSufficient) {
                        requiredBalance = total_price - wallet_balance;
                    }
                }

               // const cash_collection_count = await fetchCashCollectionCount(customer_id, nextDeliveryDate);
               // const cash_collection_status = cash_collection_count >= 1 ? 'Requested' : 'Not Requested';

                if (!isBalanceSufficient) {
                    const updatedCustomerData = {
                        ...customerData,
                        requiredBalance,
                        isBalanceSufficient,
                        total_price,
                       // cash_collection_status
                    };
                    totalCreditLimit += requiredBalance;
                    totalOrders += total_price;
                    newCustomerDataList.push(updatedCustomerData);
                }
            }
        }

        if (newCustomerDataList.length > 0) {
            setLowCreditReport(newCustomerDataList);
            setTotalCreditRequired(totalCreditLimit);
            setTotalOrdersAmount(totalOrders);
            setDataLoaded(true);
        } else {
            setInitialvalue("No Data Found");
        }

        setShowSpinner(false);
    };

    const navi = (id) => {
        const url = `/profile/${id}`;
        const newTab = window.open(url, '_blank');
        newTab.focus();
    };

    const SpinnerOverlay = () => (
        <div className="spinner-overlay">
            <div className="spinner"></div>
        </div>
    );

    const getTomorrowDate = () => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        const tableColumn = [
          "Customer ID",
          "Customer Name",
          "Phone Number",
          "Hub Name	",
          "Current wallet Balance",
          "Total Orders Amount",
          "Required Credit",
        ];
        const tableRows = lowCreditReport.map((customer) => [
          customer.customer_id,
          customer.customer_name,
          customer.customer_phone,
          customer.hub_name,
          customer.wallet_balance,
          customer.total_price,
          customer.requiredBalance,
        ]);
        doc.autoTable({
          head: [tableColumn],
          body: tableRows,
        });
    
        doc.save("LowCreditReport.pdf");
    };


    const exportToCSV = () => {
        const csvHeader = [
            "Customer ID",
            "Customer Name",
            "Phone Number",
            "Hub Name",
            "Current Wallet Balance",
            "Total Orders Amount",
            "Required Credit",
        ].join(",") + "\n"; // Joining header with commas and adding a newline
    
        const csvRows = lowCreditReport.map(customer => [
            customer.customer_id,
            customer.customer_name,
            customer.customer_phone,
            customer.hub_name,
            customer.wallet_balance,
            customer.total_price,
            customer.requiredBalance,
        ].join(",")).join("\n"); 
    
        const csvContent = csvHeader + csvRows; 

        const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "LowCreditReport.csv"); 
        document.body.appendChild(link);
    
        link.click();
        document.body.removeChild(link); 
    };
    
    return (
        <>
        {loading && ( // Render loader when loading state is true
                <div className="loader-overlay">
                    <div className="">
                        <img style={{
                            height: "6rem"
                        }} src="images/loader.gif"></img>
                    </div>
                </div>
            )}
           <div className="panel" style={{ marginTop: "10px", marginBottom: "10px" }}>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>LOW CREDIT REPORT</span>
        <div style={{ display: 'flex', alignItems: 'center' }}> {/* Flexbox for buttons */}
            {dataLoaded && 
            <ExportTableToExcel tableId="low_credit_report" fileName="low_credit_report" />
            }
            <button className="btn btn-success btn-rounded btn-sm mt-1" style={{ marginLeft: '8px' }} onClick={exportToPDF}>Export to PDF</button>
            <button className="btn btn-success btn-rounded btn-sm mt-1" style={{ marginLeft: '8px' }} onClick={exportToCSV}>Export to CSV</button>
        </div>
    </div>
</div>


            <div className="panel" style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="datepicker-container" style={{ marginTop: "10px" }}>
                    <label className="datepicker-label">Delivery Date:</label>
                    <DatePicker
                        selected={fromDate}
                        minDate={getTomorrowDate()}
                        maxDate={getTomorrowDate()}
                        onChange={handleFromDateChange}
                        dateFormat="dd-MM-yyyy"
                        className="datepicker-input"
                        placeholderText="Select date"
                    />
                </div>
                <div style={{ marginTop: '10px' }}>
                    <Button variant="outline-success" onClick={handleSearch} size='sm' disabled={loadSearch}>
                        Search
                    </Button>
                    {dataLoaded && <Button style={{marginLeft:'1rem'}} variant="outline-danger" size='sm' onClick={updateCustomerCreditLimits} disabled={updating}>Give Credit To All</Button>}
                </div>
                {dataLoaded && (
                    <>
                        <div className="card d-flex align-items-start custom-green" style={{ marginLeft: '10rem', width: '10rem', height: '5rem' }}>
                            <div className="card-body">
                                <div className="d-flex flex-row align-items-start">
                                    <span style={{ fontSize: "13px", fontWeight: "600", color: '#fff' }} className="text-facebook">Total Customers</span>
                                </div>
                                <p className="mt-2 text-muted card-text custom-card-text">{lowCreditReport.length}</p>
                            </div>
                        </div>
                        <div className="card d-flex align-items-start custom-green" style={{ marginLeft: '1rem', width: '10rem', height: '5rem' }}>
                            <div className="card-body">
                                <div className="d-flex flex-row align-items-start">
                                    <span style={{ fontSize: "13px", fontWeight: "600", color: '#fff' }} className="text-facebook">Total Order Value</span>
                                </div>
                                <p className="mt-2 text-muted card-text custom-card-text">₹ {totalOrdersAmount}</p>
                            </div>
                        </div>
                        <div className="card d-flex align-items-start custom-green" style={{ marginLeft: '1rem', width: '11rem', height: '5rem' }}>
                            <div className="card-body" style={{ marginBottom: '-1rem' }}>
                                <div className="d-flex flex-row align-items-start">
                                    <span style={{ fontSize: "13px", fontWeight: "600", color: '#fff' }} className="text-facebook">Total Credit Required</span>
                                </div>
                                <p className="mt-2 text-muted card-text custom-card-text">₹ {totalCreditRequired}</p>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }} class="table-responsive">
                {showSpinner && <div className="spinner-container"><SpinnerOverlay /></div>}
                <table id="low_credit_report" class="table table-striped">
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Customer Id</th>
                            <th>Customer Name</th>
                            <th>Phone Number</th>
                            <th>Hub Name</th>
                            <th>Current wallet Balance</th>
                            <th>Total Order Amount</th>
                            <th>Required Credit</th>
                            {/* <th>Cash Collection Status</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            lowCreditReport.map((report, index) => (
                                <tr key={index} onClick={() => navi(report.customer_id)} style={{ cursor: "pointer" }}>
                                    <td>{index + 1}</td>
                                    <td>{report.customer_id}</td>
                                    <td>{report.customer_name}</td>
                                    <td>{report.customer_phone}</td>
                                    <td>{report.hub_name}</td>
                                    <td>{report.wallet_balance}</td>
                                    <td>{report.total_price}</td>
                                    <td>{report.requiredBalance}</td>
                                    {/* <td>{fetchCashCollectionCount(report.customer_id , moment(fromDate).format('YYYY-MM-DD') , report.requiredBalance)==1 ? 'Requested' : 'Not Requested'}</td> */}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="10" style={{ textAlign: "center" }}>{initialValue}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default LowCreditReport;
