import Swal from "sweetalert2";
import db from "./../../Firebase";
import { DateTimeUtil } from "../../Utility";

const Default_Limit = 1000;
const cartDB = "cart_data";
const productDB = "products_data";
const orderDB = "order_history"
const Toast = Swal.mixin({
  toast: true,
  background: "#69aba6",
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const showToast = (icon, title) => {
  Toast.fire({
    icon,
    title,
  });
};

const addCartData = async (data) => {
  try {
    const finalData = prepareData(data);
    if (finalData.error) {
      console.log(finalData.error);
      showToast("error", `Error: ${finalData.error}`);
      return finalData;
    }

    try {
      const querySnapshot = await db
        .collection(cartDB)
        .where("customer_id", "==", finalData.customer_id)
        .get();

      const cart_data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      // console.log("cart", cart_data)
      // console.log("final", finalData)

      if (cart_data.length > 1) {
        await db
          .collection(cartDB)
          .doc(cart_data[0].id)
          .update({
            ...finalData,
          });
        showToast("success", "Cart Updated Successfully");
      } else {
        await db.collection(cartDB).add({
          ...finalData,
        });
        showToast("success", "Cart Added Successfully");
      }
      return cart_data;
    } catch (error) {
      console.log("Error fetching or updating cart: ", error);
      showToast("error", "An error occurred while updating the cart.");
    } finally {
      console.log("Fetch cart data complete...");
    }
  } catch (error) {
    console.log("Error preparing data: ", error);
    showToast("error", "An error occurred while preparing the data.");
  }
};

const prepareData = (data) => {
  const requiredCustomerFields = [
    "customer_id",
    "customer_name",
    "customer_phone",
  ];
  const requiredProductFields = [
    "product_id",
    "product",
    "product_name",
    "quantity",
  ];

  let isValid = true;
  let error = null;

  for (let prop of requiredCustomerFields) {
    if (!(prop in data)) {
      error = `${prop} is missing in customer data`;
      isValid = false;
      break;
    }
  }

  if (isValid && Array.isArray(data.products) && data.products.length > 0) {
    for (let i = 0; i < data.products.length; i++) {
      const product = data.products[i];
      for (let prop of requiredProductFields) {
        if (!(prop in product)) {
          error = `${prop} is missing in product data at index ${i + 1}`;
          isValid = false;
          break;
        }
      }
      if (!isValid) break;
    }
  } else {
    error = "products array is missing or empty";
    isValid = false;
  }

  if (!isValid) {
    return { error };
  }

  const finalData = {
    customer_id: data.customer_id || "",
    customer_name: data.customer_name || "",
    customer_phone: data.customer_phone || "",
    update_date: DateTimeUtil.timestampToISODate(new Date()),
    update_timestamp: new Date(),
    products: data.products.map((product) => ({
      product_id: product.product_id || "",
      product: product.product || "",
      product_name: product.product_name || "",
      quantity: product.quantity || 0,
    })),
  };

  return finalData;
};

export { addCartData, prepareData };

export const fetchCartData = async () => {
  try {
    let query = db.collection(cartDB);
    const snapshot = await query.get();
    const cart_data = snapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .filter((product) => product.products && product.products.length > 0);
    // console.log(cart_data);
    return cart_data;
  } catch (error) {
    console.error("Error fetching cart: ", error);
  } finally {
    console.log("Fetch cart data complete...");
  }
};

export const fetchProductsList = async () => {
  try {
    const snapshot = await db.collection(productDB).get();
    const products = snapshot.docs.map((doc) => {
      const product = { ...doc.data(), id: doc.id };
      return product;
    });
    return products;
  } catch (error) {
    console.log("product fectch error: ", error);
  }
};



export const fetchCustomerOrderHistory = async (startDate, endDate) => {
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const formattedStartDate = startDate ? DateTimeUtil.timestampToISOHyphenDate(new Date(startDate)) : DateTimeUtil.timestampToISODate(thirtyDaysAgo);
  const formattedEndDate = endDate ? DateTimeUtil.timestampToISOHyphenDate(new Date(endDate)) : DateTimeUtil.timestampToISODate(new Date());

  // console.log("Fetching order history from:", formattedStartDate, "to:", formattedEndDate);

  try {
    const query = db.collection(orderDB)
    .where("order_type", "==", "OT")
    .where("delivery_date", ">=", formattedStartDate)
    .where("delivery_date", "<=", formattedEndDate);

    const snapshot = await query.get();
    const orderHistory = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // console.log("Fetched order history:", orderHistory);
    return orderHistory;
  } catch (error) {
    console.error("Error fetching order history: ", error);
    throw new Error(`Failed to fetch order history: ${error.message}`);
  }
};