import db from "../../../Firebase";
import { collection, getDocs, query, where, limit } from 'firebase/firestore';
const Default_Limit = 100;
const formatDate = (date) => {
  if (!(date instanceof Date)) {
    console.error("Input must be a Date object", date); 
    return null; 
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
export const fetchOrderList = async (startDate, endDate) => {
  try {
      let query = db.collection("b2b_orders");
      if (startDate && endDate) {
          query = query.where("delivery_date", ">=", startDate)
                       .where("delivery_date", "<=", endDate);
      }
      const snapshot = await query.get();
      const orders = snapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data(),
      }));
      return orders;
  } catch (error) {
      console.error("Error fetching orders: ", error);
  } finally {
      // console.log("Fetch order list complete...");
  }
};

export const fetchOrderListForHub = async (startDate, endDate, hub) => {
    try {
        let query = db.collection("b2b_orders");
        query = query.where("hub_name", "==", hub);
        if (startDate && endDate) {
            query = query.where("delivery_date", ">=", startDate)
                        .where("delivery_date", "<=", endDate);
        }
        const snapshot = await query.get();
        const orders = snapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
        }));
        return orders;
    } catch (error) {
        console.error("Error fetching orders: ", error);
    } finally {
        // console.log("complete...");
    }
};
export const fetchOrderListForProduct = async (startDate, endDate, hub, product) => {
  // console.log("data fetching for", startDate, endDate, hub, product);
    try {
        let query = db.collection("b2b_orders");
        query = query.where("hub_name", "==", hub);
        query = query.where("product_name", "==", product);
        if (startDate && endDate) {
            query = query.where("delivery_date", ">=", startDate)
                        .where("delivery_date", "<=", endDate);
        }
        const snapshot = await query.get();
        const orders = snapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
        }));
        return orders;
    } catch (error) {
        console.error("Error fetching orders: ", error);
    } finally {
        // console.log("complete...");
    }
};

export const fetchUniqueHubNames = async () => {
    // console.log("Loading...");
    try {
      const hubNamesArray = [];

      let firstHubName = await getFirstHubName();
      if (firstHubName) {
        hubNamesArray.push(firstHubName);
      }

      while (true) {
        const newHubNames = await getHubNamesNotInArray(hubNamesArray);
        if (newHubNames.length === 0) {
          break;
        }
        hubNamesArray.push(...newHubNames);
      }
      // console.log("Finished...");
      return hubNamesArray;
    } catch (error) {
      console.error("Error fetching unique hubs: ", error);
    }
  };

const getFirstHubName = async () => {
    const hubCollection = collection(db, 'b2b_orders');
    const hubQuery = query(hubCollection, limit(1));
    const hubSnapshot = await getDocs(hubQuery);

    if (hubSnapshot.empty) {
      return null;
    }

    let firstHubName = null;
    hubSnapshot.forEach(doc => {
      const data = doc.data();
      if (data.hub_name) {
        firstHubName = data.hub_name;
      }
    });

    return firstHubName;
  };

  const getHubNamesNotInArray = async (hubNamesArray) => {
    const hubCollection = collection(db, 'b2b_orders');
    const hubQuery = query(hubCollection, where('hub_name', 'not-in', hubNamesArray), limit(1));
    const hubSnapshot = await getDocs(hubQuery);

    if (hubSnapshot.empty) {
      return [];
    }

    const newHubNames = [];
    hubSnapshot.forEach(doc => {
      const data = doc.data();
      if (data.hub_name) {
        newHubNames.push(data.hub_name);
      }
    });

    return newHubNames;
  };

export const fetchCafeDetails = async () => {
    try {
      const snapshot = await db.collection("b2b_orders").get()
      const orders = snapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      }));

      return orders;
    } catch (error) {
      console.error("Error fetchind orders details", error)
    }
}
