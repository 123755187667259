import React, { useState, useEffect,useContext } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Swal from 'sweetalert2'
import db from "./Firebase";
import Select from 'react-select';
import { useCookies } from "react-cookie";
import TopPanel from "./TopPanel";
import Footer from "./Footer";
import { handleLogout } from "./Utility";
import GlobalContext from "./context/GlobalContext";


function RoutesData() {
    const navigate = useNavigate();
    const { permissible_roles } = useContext(GlobalContext);
    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        console.log(loggedIn);
        if (!loggedIn) {
            navigate("/login");
        }else{
            if(permissible_roles.length>0){
                if(!permissible_roles.includes('routes')){
                    handleLogout()
                    navigate("/permission_denied");
                }
            }
        }
    }, [navigate,permissible_roles]);
    const [cookies] = useCookies(["permissions"]);
    const [timeOfDay, setTimeOfDay] = useState('');
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const initialFormState = { route: '', hub_name: '', locations: [], status: '', updated_date: new Date(), created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormState)
    const [hubNames, setHubNames] = useState([]);
    const [hubOptions, setHubOptions] = useState([]);
    const [selectedHub, setSelectedHub] = useState(null); // Set initial state to null
    const [locationOptions, setLocationOptions] = useState([]);
    const [selectedHubName, setSelectedHubName] = useState('');
    const [selectedMapOption, setSelectedMapOption] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");

    const [loading, setLoading] = useState(false);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const getCurrentTimeOfDay = () => {
            const currentTime = new Date().getHours();
            if (currentTime < 12) {
                setTimeOfDay('Good Morning');
            } else if (currentTime >= 12 && currentTime < 18) {
                setTimeOfDay('Good Afternoon');
            } else {
                setTimeOfDay('Good Evening');
            }
        };

        getCurrentTimeOfDay();
    }, []);


    useEffect(() => {
        db.collection("routes_data")
            .orderBy("route", "asc") // Order alphabetically by the "route" field in ascending order
            .onSnapshot((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }));
                // console.log("Data from Firestore:", data); // Log the data
                setData(data);
            });
    }, []);



    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value.replace(/[^\w\s.@/:+\-=]/gi, "") })
    }

    const handleClearSelection = () => {
        setSelectedMapOption(null);
    };


    useEffect(() => {
        const fetchHubNames = async () => {
            try {
                const snapshot = await db.collection('hubs_data').get();
                const hubNames = snapshot.docs.map(doc => doc.data().hub_name);
                const uniqueHubNames = [...new Set(hubNames)];
                const options = uniqueHubNames.map(name => ({ value: name, label: name }));
                setHubOptions(options);
            } catch (error) {
                console.error('Error fetching hub names:', error);
            }
        };

        fetchHubNames();
    }, []);

    // useEffect(() => {
    //     const fetchHubNames = async () => {
    //         try {
    //             const snapshot = await db.collection('locations_data').get();
    //             const hubNames = snapshot.docs.map(doc => doc.data().hub_name);
    //             const uniqueHubNames = [...new Set(hubNames)];
    //             const options = uniqueHubNames.map(name => ({ value: name, label: name }));
    //             setHubOptions(options);
    //         } catch (error) {
    //             console.error('Error fetching hub names:', error);
    //         }
    //     };

    //     fetchHubNames();
    // }, []);


    const fetchLocations = async () => {
        try {
            if (!selectedHub) {
                setLocationOptions([]);
                return;
            }
            const snapshot = await db.collection('locations_data').where('hub_name', '==', selectedHub).get();
            const locations = snapshot.docs.map(doc => {
                const data = doc.data();
                return { value: `${data.area}, ${data.subarea}`, label: `${data.area}, ${data.subarea}` };
            });
            setLocationOptions(locations);
            setSubmit({ ...submit, locations: [] }); // Clear selected locations when hub changes
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    useEffect(() => {
        fetchLocations();
    }, [selectedHub]);

    const [forceRerender, setForceRerender] = useState(false);
    // const handleHubChange = (selectedOption) => {
    //     setSelectedHubName(selectedOption ? selectedOption.value : null);
    //     setSelectedHub(selectedOption ? selectedOption.value : null);
    //     setSelectedMapOption(null);// Clear selected locations when hub changes
    // };
    const handleHubChange = (selectedOption) => {
        if (selectedOption) {
            const selectedValue = selectedOption.value;
            setSelectedHubName(selectedValue);
            setSelectedHub(selectedValue);
            setSelectedMapOption(null); // Clear selected locations when hub changes
        } else {
            // Handle case when selectedOption is null (no option selected)
            setSelectedHubName(null);
            setSelectedHub(null);
            setSelectedMapOption(null); // Clear selected locations when hub changes
        }
    };


    // const addNew = () => {
    //     setSelectedHub(null); // Clear selected Hub Name
    //     setSelectedMapOption([]); // Clear selected Map Locations
    //     setEdit(false)
    //     openModal();
    //     setSubmit({ route: '', hub_name: '', locations: [], status: '', updated_date: new Date(), created_date: new Date() });
    // }

    const addNew = () => {
        setSelectedHub(null); // Clear selected Hub Name
        setSelectedMapOption([]); // Clear selected Map Locations
        setEdit(false)
        openModal();
        setSubmit({ route: '', hub_name: '', locations: [], status: '', updated_date: new Date(), created_date: new Date() });
        // Toggle the forceRerender state to trigger a re-render
        setForceRerender(prev => !prev);
    }

    const reset = () => {
        setSubmit({ route: '', hub_name: '', locations: [], status: '', updated_date: new Date(), created_date: new Date() })
    }


    // const changeStatusForm = (data, id) => {
    //     setEdit(true)
    //     setEditID(id)
    //     setSubmit({ id: id, route: data.route, hub_name: data.hub_name, locations: data.locations, status: data.status, updated_date: new Date(), created_date: new Date() });
    //     openModal();
    // }

    const changeStatusForm = (data, id) => {
        setEdit(true);
        setEditID(id);
        setSelectedHub(data.hub_name)
        // setSelectedHubName(data.hub_name)
        setSelectedMapOption(data.locations.map(location => ({ value: location, label: location })))
        setSubmit({
            id: id,
            route: data.route,
            hub_name: data.hub_name,
            // locations: data.locations.map(location => ({ value: location, label: location })), // Format locations properly
            locations: data.locations, // Format locations properly
            status: data.status,
            updated_date: new Date(),
            created_date: new Date()
        });
        openModal();
    };


    const openModal = () => {
        // alert("ooo")
        window.modelshow();
    }

    const closeModal = () => {
        window.modalHide();
    }

    const handleLocationChange = (selectedOptions) => {
        // Update the state with the selected options
        setSubmit({ ...submit, locations: selectedOptions });
    };



    const checkRouteExists = async (route) => {
        try {
            const routeSnapshot = await db.collection("routes_data").where('route', '==', route).get();
            return !routeSnapshot.empty;
        } catch (error) {
            console.error('Error checking route existence: ', error);
            return false;
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Extract selected hub and locations
        // const selectedLocations = submit.locations.map(location => location.value);
        const selectedLocations = selectedMapOption.map(location => location.value);

        console.log(selectedLocations,submit.route,selectedHub);
        // Check if any required field is empty
        if (!submit.route || !selectedHub || selectedLocations.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill out all required fields!',
            });
            return;
        }




        if (edit) {
            setLoading(true);
            // Update existing record
            db.collection("routes_data").doc(editID).update({
                'route': submit.route,
                'hub_name': selectedHubName,
                'locations': selectedLocations,
                'status': "1",
                'updated_date': new Date(),
            }).then(() => {
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Route Updated Successfully'
                })
                closeModal();
                setLoading(false);

                // Reset form state after successful update
                setSubmit(initialFormState);
                setSelectedHub(null);
                setSelectedMapOption(null);
                setLocationOptions([]);
            }).catch(error => {
                console.error('Error updating document: ', error);
            });
        } else {
            // Check if route exists in the database
            const routeExists = await checkRouteExists(submit.route);

            if (routeExists) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Route already exists in the database!',
                });
                return;
            }
            setLoading(true);
            // Add new record
            db.collection("routes_data").add({
                'route': submit.route,
                'hub_name': selectedHubName,
                'locations': selectedLocations,
                'status': "1",
                'updated_date': new Date(),
                'created_date': new Date(),
            }).then(() => {
                reset();
                setSelectedHubName('')
                setSelectedMapOption(null);
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                closeModal();
                setLoading(false);


                Toast.fire({
                    icon: 'success',
                    title: 'Route Added'
                })
            }).catch(error => {
                console.error('Error adding document: ', error);
            });
        }
    };

    const deleteData = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            setLoading(true);
            if (result.isConfirmed) {
                db.collection("routes_data").doc(id).delete().then(() => {

                    setSubmit({ route: '', hub_name: '', locations: [], status: '', updated_date: new Date(), created_date: new Date() })
                    closeModal();
                    setLoading(false);
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })

            }
        })

    }



    // Filter data based on search query
    const filteredDataLocation = data.filter(({ data }) => {
        // Filter based on search query
        return (
            data.hub_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            data.route.toLowerCase().includes(searchQuery.toLowerCase()) ||
            data.locations.some(location => location.toLowerCase().includes(searchQuery.toLowerCase()))
        );
    });

    // Determine the range of page numbers to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredDataLocation.slice(indexOfFirstItem, indexOfLastItem);


    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        const total = Math.ceil(data.length / itemsPerPage);
        setTotalPages(total);
    }, [data.length, itemsPerPage]);


    const renderPageButtons = () => {
        const pageButtons = [];
        // Determine the range of page numbers to display
        let startPage = Math.max(1, currentPage - 5);
        let endPage = Math.min(totalPages, startPage + 9);

        // If the total number of pages is less than 10, adjust the endPage
        if (totalPages <= 10) {
            endPage = totalPages;
        } else {
            // If the current page is near the start, display the first 10 pages
            if (currentPage <= 5) {
                startPage = 1;
                endPage = 10;
            }
            // If the current page is near the end, display the last 10 pages
            else if (currentPage >= totalPages - 4) {
                endPage = totalPages;
                startPage = endPage - 9;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageButtons.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button onClick={() => paginate(i)} className="page-link" style={{ color: "black" }}>{i}</button>
                </li>
            );
        }
        return pageButtons;
    };


    const rolePermission = () => {
        const Toast = Swal.mixin({
            toast: true,
            background: '#d7e7e6',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
        });

        Toast.fire({
            icon: 'error',
            title: 'You are not authorised to do this action'
        });

    }



    return (
        <>
            {loading && ( // Render loader when loading state is true
                <div className="loader-overlay">
                    <div className="">
                        <img style={{
                            height: "6rem"
                        }} src="images/loader.gif"></img>
                    </div>
                </div>
            )}
            <div class="container-scroller">

                <TopPanel />

                <div class="container-fluid page-body-wrapper">

                    <Sidebar />

                    <div class="main-panel">
                        <div class="content-wrapper">

                            <div class="col-lg-12 grid-margin stretch-card">
                                <div class="card">
                                    <div class="card-body">
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <h4 class="card-title">Routes</h4>

                                            <p class="card-description">
                                                {permissible_roles.includes('add_route') ? <> <button type="button" class="btn btn-success btn-rounded btn-sm" onClick={() => addNew()}>Create Routes</button></> : <> <button type="button" class="btn btn-success btn-rounded btn-sm" onClick={() => rolePermission()}>Create Routes</button></>}

                                            </p>
                                        </div>
                                        <input
                                            style={{
                                                border: "1px solid grey",
                                                padding: "0px 4px 0px 1rem;",
                                                borderRadius: "1rem",
                                                marginTop: "3px",
                                                marginBottom: "1rem",
                                                paddingLeft: "1rem",
                                                height: "32px",
                                                paddingBottom: "0px"
                                            }}
                                            type="text"
                                            placeholder="Search here"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Sr No.
                                                        </th>
                                                        <th>
                                                            Routes
                                                        </th>

                                                        <th>
                                                            Hub Name
                                                        </th>
                                                        <th>
                                                            Location
                                                        </th>

                                                        <th>
                                                            Action
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentItems.map(({ id, data }, index) => {
                                                        const itemIndex = indexOfFirstItem + index + 1;
                                                        return (
                                                            <tr key={id}>
                                                                <td>{itemIndex}</td>
                                                                <td style={{ width: "10%" }}>
                                                                    {data.route}
                                                                </td>
                                                                <td>
                                                                    {data.hub_name}
                                                                </td>
                                                                <td>
                                                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                                        {data.locations.map((location, index) => (
                                                                            <label key={index} className="badge badge-success mr-2" style={{ color: "black", marginRight: "0.5rem", marginBottom: "0.5rem", marginTop: "0.5rem", fontSize: "60%", fontWeight: "700" }}>{location}</label>
                                                                        ))}
                                                                    </div>
                                                                </td>


                                                                <td>
                                                                    {permissible_roles.includes('edit_route') ? <> <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => changeStatusForm(data, id)} class="btn btn-dark btn-sm"><i class="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i></button></> : <> <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => rolePermission()} class="btn btn-dark btn-sm"><i class="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i></button></>}
                                                                    {permissible_roles.includes('delete_route') ? <><button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} class="btn btn-dark btn-sm" onClick={() => deleteData(id)}><i class="menu-icon mdi mdi-delete" style={{ color: "white" }}></i></button></> : <><button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} class="btn btn-dark btn-sm" onClick={() => rolePermission()}><i class="menu-icon mdi mdi-delete" style={{ color: "white" }}></i></button></>}

                                                                </td>
                                                            </tr>
                                                        );
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                        {/* Pagination */}
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <button onClick={() => paginate(currentPage - 1)} className="page-link" disabled={currentPage === 1}>Previous</button>
                                            </li>
                                            {renderPageButtons()}
                                            <li className="page-item">
                                                <button onClick={() => paginate(currentPage + 1)} className="page-link" disabled={currentPage === totalPages}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="exampleModal-2" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel-2">Create Routes</h5>
                                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form class="forms-sample" onSubmit={handleSubmit}>
                                                <div class="form-group">
                                                    <label >Route</label>
                                                    <input type="text" class="form-control" onChange={handleChange} id="route" value={submit.route} required />
                                                </div>

                                                <div class="form-group">
                                                    <label for="exampleSelectGender">Hub Name</label>

                                                    <Select
                                                        options={hubOptions}
                                                        onChange={handleHubChange}
                                                        value={hubOptions.find((option) => option.value === selectedHub)}
                                                        placeholder="Select Hub Name"
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleSelectGender">Map Locations</label>

                                                    <Select
                                                        value={selectedMapOption}
                                                        onChange={setSelectedMapOption}
                                                        options={locationOptions}
                                                        isMulti
                                                    />
                                                </div>


                                                <div class="modal-footer">
                                                    <button type="submit" value="submit" class="btn btn-success">Submit</button>
                                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                        <Footer />

                    </div>

                </div>

            </div>
        </>
    )
}

export default RoutesData