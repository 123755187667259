import React,{useState,useEffect,useContext} from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetch_records_with_pagination,fetch_records,fetch_all_records } from './helpers';
import { Spinner } from 'react-bootstrap';
import Select from "react-select"; 
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import GlobalContext from "./context/GlobalContext";
import { handleLogout } from './Utility';
const WalletTxnsReport = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filters, setFilters] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [lastVisible, setLastVisible] = useState(null);
  const [hubsData, setHubsData] = useState([]);
  const [selectedHubs, setSelectedHubs] = useState([]);
  const [hubUserData, setHubUserData] = useState([]);

  const navigate = useNavigate();
    const {permissible_roles} = useContext(GlobalContext);
    
  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    console.log(loggedIn);
    if (!loggedIn) {
        navigate("/login");
    }else{
        if(permissible_roles.length>0){
            if(!permissible_roles.includes('wallet_transaction_report')){
                handleLogout()
                navigate("/permission_denied");
            }
        }
    }
}, [navigate,permissible_roles]);

  const handleDownloadPdf = async()=>{
    const doc = new jsPDF();
    const tableColumn = [
      "SR.NO",
      "Txn ID",
      "Name",
      "Contact",
      "Hub",
      "Type",
      "Amount",
      "last Ledger Amount",
      "Description",
      "Reason",
      "Source",
      "Date & Time",
      "User",
      "Delivery Executive"
    ];

    const tableRows = data.map((wallet, index) => [
      index + 1,
      wallet.data.txn_id,
      wallet.data.customer_name,
      wallet.data.customer_phone,
      wallet.data.hub_name,
      wallet.data.type,
      wallet.data.amount,
      wallet.data.current_wallet_balance,
      wallet.data.description,
      wallet.data.reason,
      wallet.data.source,
      wallet.data.created_date && wallet.data.created_date.toDate().toISOString(),
      wallet.data.user,
      wallet.data.delivery_executive
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
      styles: {
        fontSize: 6,
        cellPadding: 1,
      },
    });

    doc.save("WalletTxnsReport.pdf");
  }


  const reasonsOptions = [
  { label: "Cash Deposit", value: "Cash Deposit" },
  { label: "Adjustment", value: "Adjustment" },
  { label: "Auto Generated", value: "Auto Generated" },
  { label: "Via Payment Gateway", value: "Via Payment Gateway" },
  { label: "Manual Order Executed", value: "Manual Order Executed" },
  {label:"Cron",value:"Cron"},
];


const sourceOptions = [
  { label: "Backend", value: "Backend"},
  { label: "Online", value: "Online" },
  { label: "Server", value: "Server" },
  { label: "Deivery Man Application", value: "delivery man application" },
  { label: "Application", value: "application" },
  { label: "Bulk Wallet Upload", value: "bulk wallet upload" },
  
];


const exportTableToExcel = () => {
  const ws = XLSX.utils.json_to_sheet(data.map(item => ({
    'Txn ID': item.data.txn_id,
    'Name': item.data.customer_name,
    'Contact': item.data.customer_phone,
    'Hub': item.data.hub_name,
    'Type': item.data.type,
    'Amount': item.data.amount,
    'last Ledger Amount': item.data.current_wallet_balance,
    'Description': item.data.description,
    'Reason': item.data.reason,
    'Source': item.data.source,
    'Date & Time': item.data.created_date && item.data.created_date.toDate().toISOString(),
    'User': item.data.user,
    'Delivery Executive': item.data.delivery_executive
  })));

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Subscriptions');
  XLSX.writeFile(wb, 'wallet_transaction_report.csv');
};
  
  useEffect(() => {
    setLoading(true);
    if (filters.length !== 0) {
      fetch_records_with_pagination('wallet_history', filters).then((data) => {
        console.log(data);
        setLastVisible(data.lastVisible);
        setTotalPages(data.totalPages);
        setTotalCount(data.totalCount);
        setPage(data.currentPage);
        setData(data.data);
        setLoading(false); 
      }).catch((error) => {
        console.error(error);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    console.log(filters);
  }, [filters,page]);


  useEffect(()=>{
    fetch_all_records('hubs_data').then((data) => {
     
      setHubsData(data);
    }
  );
  },[])

  useEffect(()=>{
    console.log(selectedHubs);
    if(selectedHubs.length !== 0) {
      fetch_records('hubs_users_data', [{key: 'hub_name', value: selectedHubs, operator: 'in'}]).then((data) => {
        setHubUserData(data);
      }
    );
  }
  },[selectedHubs])

  const updateFilters = (key, value, operator,action_type="") => {
    console.log("The already filters are",filters);
    console.log("getting called",action_type)
    setFilters((prevFilters) => {
      const existingFilterIndex = prevFilters.findIndex(
        (filter) => filter.key === key && filter.operator === operator
      );
      console.log(existingFilterIndex);
      if (action_type === "remove") {
        if (existingFilterIndex !== -1) {
          const updatedFilters = [...prevFilters];
          updatedFilters.splice(existingFilterIndex, 1);
          console.log("calling the update filter",updatedFilters);
          return updatedFilters;
        }
        return prevFilters;
      }

      if (existingFilterIndex !== -1) {
        const updatedFilters = [...prevFilters];
        updatedFilters[existingFilterIndex].value = value;
        return updatedFilters;
      } else {

        return [...prevFilters, { key, value, operator }];
      }
    });

    
    
  };
  const handlesetStartDate = (data) => {
    const startOfDay = new Date(data);
    startOfDay.setHours(0, 0, 0, 0);
    setStartDate(startOfDay);
    updateFilters('created_date', startOfDay, '>=');
  }
  
  const handleEndDate = (data) => {
    const endOfDay = new Date(data);
    endOfDay.setHours(23, 59, 59, 999);
    setEndDate(endOfDay);
    updateFilters('created_date', endOfDay, '<=');
  }
  
  const handleHubChange = (selected_values)=>{
    if(selected_values.length===0){
      updateFilters('hub_name', '', 'in', 'remove');
      return;
    }else{
      let values = []
      selected_values.forEach(value => {
        values.push(value.value);
      });
      setSelectedHubs(values);
      updateFilters('hub_name', values, 'in');
    }
  }

  const handleDeliveryExecutiveChange = (selected_values)=>{
    console.log("The selected value",selected_values);
    if(selected_values.length===0){
      updateFilters('delivery_executive', '', 'in', 'remove');
      return;
    }else{

      let values = []
      selected_values.forEach(value => {
        values.push(value.value);
      });
      console.log(values);
      updateFilters('delivery_executive', values, 'in');
    }
  }

  const handleTypeChange =(e)=>{
    let values = []
    try {
    console.log(e);
    values.push(e.value);
    values.push(e.value.toLowerCase());
  
    updateFilters('type',values, 'in');
    } catch (error) {
      console.log(error);
      updateFilters('type', values, 'in', 'remove');
      
    }
  }


  const handleReasonChange = (selected_valued) => {
    if(selected_valued.length===0){
      updateFilters('reason', '', 'in', 'remove');
      return;
    }else{
      let values = []
      selected_valued.forEach(value => {
        values.push(value.value);
      });
      
      updateFilters('reason',values, 'in');
    }

    console.log(selected_valued);
  }

  const handleSourceChange = (selected_valued)=>{
    if(selected_valued.length===0){
      updateFilters('source', '', 'in', 'remove');
      return;
    }else{
      let values = []
      selected_valued.forEach(value => {
        values.push(value.value);
      });
      updateFilters('source', values, 'in');
    }
  }

  
  return (
    <>
     <div className='container'>
        <h3 className='page_heading'>Wallet Transactions</h3>

        <div className='filter_containers'>
          <div className='d-flex flex-column'>
            <label>From Date:</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => handlesetStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="dd/MM/yyyy"
              className="datepicker-input"
              placeholderText="From Date"
              required
            />
          </div>
          <div  className='d-flex flex-column'>
            <label>To Date:</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => handleEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="dd/MM/yyyy"
              className="datepicker-input"
              placeholderText="To Date"
              required
            />
          </div>
          <div className='d-flex flex-column'>
            <label>Hubs</label>
            <Select
              isMulti
              options={hubsData.map(hub => ({label: hub.data.hub_name, value: hub.data.hub_name}))}
              onChange={(selectedOption) => handleHubChange(selectedOption)}
              placeholder="Select Hub"
              required
            />
          </div>
          <div className='d-flex flex-column'>
            <label>Delivery Executive</label>
            <Select
              isMulti
              options={hubUserData.map(hub => ({label: hub.data.first_name + ' ' + hub.data.last_name, value: hub.data.first_name + ' ' + hub.data.last_name}))}
              onChange={(selectedOption) => handleDeliveryExecutiveChange(selectedOption)}
              placeholder="Select Delivery Executive"
              required
            />
          </div>
          <div className='d-flex flex-column'>
            <label>Type</label>
            <Select
              options={[{label: 'Credit', value: 'Credit'}, {label: 'Debit', value: 'Debit'}]}
              onChange={(selectedOption) => handleTypeChange(selectedOption)}
              placeholder="Select Type"
              required
              isClearable
            />
          </div>

          <div className='d-flex flex-column'>
            <label>Reason</label>
            <Select
              isMulti
              options={reasonsOptions}
              onChange={(selectedOption) => handleReasonChange(selectedOption)}
              placeholder="Select Reason"
              required
            />
          </div>
          <div className='d-flex flex-column'>

            <label>Source</label>
            <Select
              isMulti
              options={sourceOptions}
              onChange={(selectedOption) => handleSourceChange(selectedOption)}
              placeholder="Select Source"
              required
            />

          </div>
          <div className='d-flex flex-column'>
            <button className='btn btn-primary' onClick={()=>{handleDownloadPdf()}} style={{
            marginTop: '1rem',
              height: '2.5rem',
            }}>Download PDF</button>
          </div>

          <div className='d-flex flex-column'>
            <button className='btn btn-primary' onClick={()=>{exportTableToExcel()}} style={{
            marginTop: '1rem',
              height: '2.5rem',
            }}>Download Excel</button>
            </div>
        </div>
     </div>
     {loading ? <>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Spinner animation="border" variant="primary" />
        </div>
        
        </>:<>
        <div className='custom_container' style={{
          overflowX: 'auto',
          overflowY: 'auto'

        }}>
          <div className='d-flex' style={{
            justifyContent: 'right',
            gap: '1rem'

          }}>
            <h4 className='pagination_text'>Total Records: {totalCount}</h4>
          </div>
          <table className='table custom-table'>
            <thead>
              <tr>
                <th>SR.NO</th>
                <th>Txn ID</th>
                <th>Name</th>
                <th>Contact</th>
                <th>Hub</th>
                <th>Type</th>
                <th>Amount</th>
                <th>last Ledger Amount</th>
                <th>Description</th>
                <th>Reason</th>
                <th>Source</th>
                <th>Date & Time</th>
                <th>User</th>
                <th>Delivery Executive</th>
              </tr>
            </thead>
            <tbody>
              {data.map((wallet, index) => (
                <tr key={index}>
                  <td>
                    {index + 1}
                  </td>
                  <td>
                    {wallet.data.txn_id}
                  </td>
                  <td>
                    {wallet.data.customer_name}
                  </td>
                  <td>
                    {wallet.data.customer_phone}
                  </td>
                  <td>{wallet.data.hub_name}</td>
                  <td>{wallet.data.type}</td>
                  <td>{wallet.data.amount}</td>
                  <td>{wallet.data.current_wallet_balance}</td>
                  <td>{wallet.data.description}</td>
                  <td>{wallet.data.reason}</td>
                  <td>{wallet.data.source}</td>
                  <td>
    {wallet.data.created_date && typeof wallet.data.created_date.toDate === 'function'
        ? wallet.data.created_date.toDate().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
        : 'Invalid Date'}
</td>
                  <td>{wallet.data.user}</td>
                  <td>{wallet.data.delivery_executive}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </>}
    </>
  )
}

export default WalletTxnsReport