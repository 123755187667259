import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetch_records, fetch_all_records } from '../helpers';
import Footer from '../Footer';
import TopPanel from '../TopPanel';
import Sidebar from '../Sidebar';
import { CreateConversastionLog } from '../forms';
import { ConversastionLogCard } from '../components';
import { Spinner } from 'react-bootstrap';



const Lead = () => {
    const navigate = useNavigate();
    const { task_id } = useParams();
    const [loading, setLoading] = useState(false);
    const [taskDetails, setTaskDetails] = useState(null);
    const [activePopup, setActivePopup] = useState("");
    const [dispositions, setDispositions] = useState([]);
    const [tags, setTags] = useState([]);
    const [customer_data, setCustomerData] = useState(null);
    const [conversation_logs, setConversationLogs] = useState([]);
    const [refresh, setRefresh] = useState("");
    // const [assignedTo, setAssignedTo] = useState("");
 const [availableUsers, setAvailableUsers] = useState([]);
    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        if (!loggedIn) {
            navigate("/login");
        }
    }, [navigate]);



    useEffect(() => {
        if (task_id) {
            setLoading(true);

            fetch_records("tasks", [
                {
                    "key": "task_id",
                    "value": task_id,
                    "operator": "=="
                }
            ]).then((data) => {
                setLoading(false);
                if (data.length > 0) {
                    console.log("task details", data[0].data);
                    setTaskDetails(data[0]);
                    fetch_records("customers_data", [
                        {
                            "key": "customer_id",
                            "value": data[0].data.customer_id,
                            "operator": "=="
                        }
                    ]).then((data) => {
                        if (data.length > 0) {
                            console.log("customer details", data[0]);
                            setCustomerData(data[0]);
                        } else {
                            console.log("No data found for the provided customer_id");
                        }
                    }
                    ).catch((error) => {
                        console.error("Error fetching customer details:", error);
                    }
                    );


                } else {
                    console.log("No data found for the provided task_id");
                }
            }).catch((error) => {
                setLoading(false);
                console.error("Error fetching task details:", error);
            });
        }
    }, [task_id]);

    useEffect(() => {
        fetch_records("conversation_logs", [
            {
                "key": "task_id",
                "value": task_id,
                "operator": "=="
            }
        ]).then((data) => {
            console.log("Fetched data:", data);  
            if (data.length > 0) {
                console.log("conversation logs------------------", data);
                setConversationLogs(data);
            } else {
                console.log("No data found for the provided task_id");
            }
        }).catch((error) => {
            console.error("Error fetching conversation logs:", error);
        });
    }, [refresh]);


    const togglePopup = (value) => {
        setActivePopup(value);
    };
    useEffect(() => {
        fetch_all_records('dispositions').then((data) => {
            setDispositions(data);

        });
        fetch_all_records('tags').then((data) => {
            setTags(data);
        });
        fetch_records('users', [
              {
                "key": "role",
                "value": ["Customer Care Agent", "Customer Support Team Lead"], // Specify required roles
                "operator": "in"
              }
            ]).then((data) => {
              setAvailableUsers(data); 
            });

    }
        , []);

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="">
                        <img style={{ height: "6rem" }} src="/images/loader.gif" alt="Loading..." />
                    </div>
                </div>
            )}
            <div className="container-scroller">
                <TopPanel />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            {taskDetails ? (
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Task Details</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <p><strong>Customer Name:</strong> {taskDetails.data.customer_name}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p><strong>Customer Phone:</strong> {taskDetails.data.customer_phone}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p><strong>Assigned To:</strong>
                                                <select>
                                                {availableUsers.map((user, index) => (
                                                <option key={index} value={user.data.username}>
                                                    {user.data.first_name} {user.data.last_name}
                                                </option>
                                                ))}
                                                </select>
                                                 </p>             
                                            </div>
                                            <div className="col-md-4">
                                                <p><strong>Status:</strong>
                                                    <select >
                                                        <option value="LEAD">LEAD</option>
                                                        <option value="CUSTOMER">CUSTOMER</option>
                                                        <option value="SUBSCRIBER">SUBSCRIBER</option>
                                                    </select>
                                                </p>
                                            </div>
                                            <div className="col-md-4">
                                                <p><strong>Attempts:</strong> {taskDetails.data.attempts}</p>
                                            </div>
                                            <div className="col-md-4 mt-2">
                                                <p><button className="c_btn" onClick={() => togglePopup("conversation_logs")}>+ Create Conversation</button></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <p>No task details available.</p>
                            )}


                            {activePopup === "conversation_logs" && (
                                <div className="popup">
                                    <div className="popup-inner">
                                        <div className='close_btn_position'>
                                            <button className="close_btn" onClick={() => togglePopup("")}>
                                                X
                                            </button>
                                        </div>
                                        <CreateConversastionLog customer_data={customer_data} setActivePopup={setActivePopup} tags={tags} disposition={dispositions} from="ONBOARD_PAGE" task_data={taskDetails} setRefresh={setRefresh} />
                                    </div>
                                </div>
                            )}

                        </div>

                        {conversation_logs.length > 0 ? <>
                            <div className="container">
                                {conversation_logs.map((log) => (
                                    <ConversastionLogCard key={log.id} data={log.data} />
                                ))}
                            </div>
                        </> : <>

                            {/* <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '50vh'
                            }}>

                                <Spinner />
                            </div> */}
                        </>}

                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Lead;
