import React from "react";
import { Button } from "react-bootstrap";

const ExportToCSV = ({ csvColumns, csvData, CSV_FileName }) => {
  const exportToCSV = () => {
    const csvRows = csvData.map((row, index) => {
      return row.map((cell,index) => cell).join(",");
    });
    const csvContent = [csvColumns.join(","), ...csvRows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${CSV_FileName}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button onClick={exportToCSV} className="btn btn-success btn-rounded btn-sm">
      Export CSV
    </Button>
  );
};

export default ExportToCSV;