import React, { useState, useEffect } from "react";
import { fetch_all_records, fetch_records,create_record,generate_random_id,update_record } from '../../helpers';
import { ROLES,CONVERSASTION_LOGS_REQUIRED_FIELD,INTERACTION_TYPE_REQUIRED_FIELDS,FOLLOW_REQUIRED_FIELD,TASK_TYPE,TASK_STATUS } from '../../constants';
import { getUserInfo } from '../../Utility';
import Swal from "sweetalert2";
import Select from 'react-select';
const CreateConversastionLog = ({customer_data,setActivePopup,tags,disposition,from,task_data=null,setRefresh}) => {

  console.log("customer_data from create conversastion logs",customer_data.data);
  const { loggedIn, userId, username, loggedIn_user } = getUserInfo();
  const [loading, setLoading] = useState(false);
  const [dispositions, setDispositions] = useState([]);
  useEffect(() => {
    setDispositions(disposition);
  
    // Fetch users with specific roles
    fetch_records('users', [
      {
        "key": "role",
        "value": ["Customer Care Agent", "Customer Support Team Lead"], 
        "operator": "in"
      }
    ]).then((data) => {
      setAvailableUsers(data); // Set the filtered users
    });
  
    // Fetch all products
    fetch_records('products_data', [
      {
        "key": "inStock",
        "value": true,
        "operator": "=="
      }
    ]).then((data) => {
      setAllProducts(data); 
    });
    
    // Set available tags
    setAvailableTags(tags);
  }, []);
  

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const [selectedDisposition, setSelectedDisposition] = useState("");
  const [subDispositionOptions, setSubDispositionOptions] = useState([]);
  const [selectedSubDisposition, setSelectedSubDisposition] = useState("");
  const [followUpRequired, setFollowUpRequired] = useState(null);
  const [followUpDate, setFollowUpDate] = useState(getCurrentDate());
  const [interactionType, setInteractionType] = useState("");
 
  const [availableUsers, setAvailableUsers] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const handleDispositionChange = (e) => {
    const selected_dispo = e.target.value;
    setSelectedDisposition(selected_dispo);

    const option_slelected_sub_dispositions = dispositions.filter(
      (disposition) => disposition.data.name === selected_dispo
    );

    setSubDispositionOptions(option_slelected_sub_dispositions[0].data.subdispositions);
  };

  const handleFollowUpChange = (e) => {
    setFollowUpRequired(e.target.value === "yes");
  };




  const handleProductChange = (selectedOptions) => {
    setSelectedProducts(selectedOptions.map(option => option.value));
  };

  const handleTagChange = (selectedOptions) => {
    setSelectedTags(selectedOptions.map(option => option.value));
  };


  const handleFormSubmit = () => {
    let form_data = {
      interaction_type: interactionType,
      disposition: selectedDisposition,
      sub_disposition: selectedSubDisposition,
      conversation_notes: document.getElementById("conversationNotes").value,
      followup_required: followUpRequired,
      follow_up_date: followUpDate,
      created_at: new Date(),
      tags: selectedTags,
      selected_products: selectedProducts,
      customer_id: customer_data.data.customer_id,
      customer_phone: customer_data.data.customer_phone,
      customer_email: customer_data.data.customer_email,

      created_by: loggedIn_user
    };
    console.log(form_data);
  
    const formatFieldName = (field) => {
      return field.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };
  
    const missingFields = [];
    CONVERSASTION_LOGS_REQUIRED_FIELD.forEach((field) => {
      console.log("field",field);
      if (form_data[field] === "" || form_data[field] === null || form_data[field] === undefined) {
        if(field === "followup_required" && from === "ONBOARD_PAGE"){
        }else{
          missingFields.push(formatFieldName(field));

        }
      }
    });
    console.log("missing fields",missingFields);
    if (interactionType === "call"){
      try {
        form_data.call_type = document.getElementById("callType1").value;
        form_data.res_type = document.getElementById("callType2").value;
      } catch (error) {
        
      }
    }
    if(interactionType === "email"){
      try {
        form_data.email_subject = document.getElementById("emailSubject").value;
      } catch (error) {

      }
    }
    if(followUpRequired){
      try {
        form_data.assigned_to = document.getElementById("assignedTo").value;
      } catch (error) {
        
      }
    }
  
    if (missingFields.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        html: `<ul>${missingFields.map(field => `<li>${field} is required</li>`).join('')}</ul>`,
      });
      return; 
    }
  
    if (INTERACTION_TYPE_REQUIRED_FIELDS[interactionType]) {
      const missingInteractionTypeFields = [];
      INTERACTION_TYPE_REQUIRED_FIELDS[interactionType].forEach((field) => {
        if (form_data[field] === "" || form_data[field] === null || form_data[field] === undefined) {
          missingInteractionTypeFields.push(formatFieldName(field));
        }
      });

  
      if (missingInteractionTypeFields.length > 0) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `<ul>${missingInteractionTypeFields.map(field => `<li>${field} is required</li>`).join('')}</ul>`,
        });
        return; 
      }
    }
  
    if (followUpRequired) {
      const missingFollowUpFields = [];
      FOLLOW_REQUIRED_FIELD.forEach((field) => {
        if (form_data[field] === "" || form_data[field] === null || form_data[field] === undefined) {
          missingFollowUpFields.push(formatFieldName(field));
        }
      });
      
      if (missingFollowUpFields.length > 0) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `<ul>${missingFollowUpFields.map(field => `<li>${field} is required</li>`).join('')}</ul>`,
        });
        return;
    }
  }
  setLoading(true);
  if(followUpRequired){
    let task_id = generate_random_id(8);
    form_data.task_id = task_id
    form_data.task_type = TASK_TYPE.FOLLOW_UP;
    let task_data = {
      task_type: TASK_TYPE.FOLLOW_UP,
      task_date: followUpDate,
      assigned_to: form_data.assigned_to,
      created_by: loggedIn_user,
      created_at: new Date(),
      task_status: TASK_STATUS.PENDING,
      customer_id: customer_data.data.customer_id,
      customer_phone: customer_data.data.customer_phone,
      customer_email: customer_data.data.customer_email,
      task_id: task_id
    }
    create_record('tasks',task_data).then((res)=>{
      
    })
  }

  if(task_data){
    form_data.task_id = task_data.data.task_id;
    update_record('tasks',task_data.id,{
      "attempts":task_data.data.attempts + 1,
    }).then((res)=>{
        
      })

  }
    
    create_record('conversation_logs', form_data).then((res) => {

      setLoading(false);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Conversation Log created successfully',
      });
      setActivePopup("");
      setRefresh("refresh");
    }).catch((error) => {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    }
    );
  };

  return (
    <div>
      <div class="">
        <div
        >
          <div className="form-group">
            <label htmlFor="interactionType">Interaction Type</label>
            <select
              className="form-select"
              id="InteractionType"
              aria-label="Default select example"
              onChange={(e) => setInteractionType(e.target.value)}
              required
            >
              <option value="" disabled selected hidden>Choose...</option>
              <option value="call">Call</option>
              <option value="email">Email</option>
            </select>
          </div>
          {interactionType === "call" && (
            <>
              <div className="form-group">
                <label htmlFor="callType1">Call Type</label>
                <select
                  className="form-select"
                  id="callType1"
                  aria-label="Default select example"
                >
                  <option value="" disabled selected hidden>Choose...</option>
                  <option value="inbound">Inbound</option>
                  <option value="outbound">Outbound</option>
                </select>
              </div>
              
            </>
          )}

          {interactionType === "email" && (
            <div className="form-group">
              <label htmlFor="emailSubject">Email Subject</label>
              <input
                type="text"
                className="form-control"
                id="emailSubject"
                placeholder="Enter email subject"
              />
            </div>
          )}

          <div className="form-group">
            <label htmlFor="Disposition">Disposition</label>
            <select
              className="form-select"
              id="Disposition"
              aria-label="Default select example"
              onChange={handleDispositionChange}
              required
            >
              <option value="" disabled selected hidden>Choose...</option>
              {dispositions.map((disposition, index) => (
                <option key={index} value={disposition.data.name}>
                  {disposition.data.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="subDisposition">Sub Disposition</label>
            <select
              className="form-select"
              id="subDisposition"
              aria-label="Default select example"
              disabled={subDispositionOptions.length === 0}
              required
              onChange={(e) => setSelectedSubDisposition(e.target.value)}
            >
              <option value="" disabled selected hidden>Choose...</option>
              {subDispositionOptions.map((option, index) => (
                <option
                  key={index}
                  value={option.toLowerCase().replace(/\s+/g, "-")}
                >
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
        <label htmlFor="products">Select Products:</label>
        <Select
    id="products"
    isMulti
    options={allProducts.map((product) => ({
      value: product.data.productName,
      label: product.data.productName,
    }))}
    value={selectedProducts.map((product) => ({
      value: product,
      label: product,
    }))}
    onChange={(selectedOptions) =>
      setSelectedProducts(selectedOptions.map((option) => option.value))
    }
  />
      </div>

      {/* Selected Products */}
      <div className="form-group">
        <label>Selected Products:</label>
        <div className="d-flex flex-wrap mt-2" style={{ gap: "5px" }}>
          {selectedProducts.map((product, index) => (
            <p key={index} className="subdispo_chip">{product}</p>
          ))}
        </div>
      </div>

      {/* Tag Multi-Select */}
      <div className="form-group">
        <label htmlFor="tags">Select Tags:</label>
        <Select
          options={availableTags.map(tag => ({
            value: tag.data.tag_name,
            label: tag.data.tag_name,
          }))}
          isMulti
          onChange={handleTagChange}
          placeholder="Select tags..."
          value={availableTags
            .filter(tag => selectedTags.includes(tag.data.tag_name))
            .map(tag => ({
              value: tag.data.tag_name,
              label: tag.data.tag_name,
            }))}
        />
      </div>

      {/* Selected Tags */}
      <div className="form-group">
        <label>Selected Tags:</label>
        <div className="d-flex flex-wrap mt-2" style={{ gap: "5px" }}>
          {selectedTags.map((tag, index) => (
            <p key={index} className="subdispo_chip">{tag}</p>
          ))}
        </div>
      </div>
          <div class="form-group">
            <label for="conversationNotes">Conversation Notes</label>
            <textarea
              style={{ minHeight: "200px" }}
              class="form-control"
              id="conversationNotes"
              placeholder="Enter conversation notes"
              required
            ></textarea>
          </div>
          {from !== "ONBOARD_PAGE" && (
            <div className="form-group">
            <label>Follow-Up Required:</label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                paddingLeft: "30px",
                gap: "25px",
              }}
            >
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="followUpRequired"
                  id="followUpYes"
                  value="yes"
                  onChange={handleFollowUpChange}
                  required
                />
                <label htmlFor="followUpYes">Yes</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="followUpRequired"
                  id="followUpNo"
                  value="no"
                  onChange={handleFollowUpChange}
                  required
                />
                <label htmlFor="followUpNo">No</label>
              </div>
            </div>
          </div>
          )}
          
          {followUpRequired === true && (<>
            <div className="form-group">
              <label htmlFor="followUpDate">Follow-Up Date</label>
              <input
                type="date"
                className="form-control"
                id="followUpDate"
                value={followUpDate}
                onChange={(e) => setFollowUpDate(e.target.value)}
                required={followUpRequired === true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="assignedTo">Assigned To</label>
              <select
                className="form-select"
                id="assignedTo"
                aria-label="Default select example"
              >
                <option value="" disabled selected hidden>Choose...</option>
                {availableUsers.map((user, index) => (
                  <option key={index} value={user.data.username}>
                    {user.data.first_name} {user.data.last_name}
                  </option>
                ))}
              </select>
            </div>

          </>
          )}

          <button  class="btn btn-primary" disabled={loading} onClick={(e)=>handleFormSubmit(e)}>
            {loading ? "creating..." : "Craete Conversation Log"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateConversastionLog;
