import React, { useState, useEffect, useMemo } from "react";
import TopPanel from "../../../TopPanel";
import Sidebar from "../../../Sidebar";
import Footer from "../../../Footer";
import { addCartData, fetchCartData, fetchProductsList } from "../query";
import { CartMatrix, mapCartProducts } from "./fucntions";
import { DateTimeUtil, TimeAgo } from "../../../Utility";
// import Export from "../../../components/Export/Export";

const MarkatingDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [rawData, setRawData] = useState(null);
  const [details, setDetails] = useState(null);
  const [detailsMatrix, setDetailsMatrix] = useState(null);
  const [columns, setColumns] = useState([]);
  const [exportData, setExportData] = useState([["No Data Found"]]);
  const [selectedProduct, setSelectedProduct] = useState("All Product");
  const [productList, setProductList] = useState([]);
  const [exportSelectedData, setExportSelectedData] = useState([[]]);

  // Memoized table headers based on the selected product
  const tableHeader = useMemo(() => {
    if (selectedProduct === "All Product") {
      return [
        "Customer ID",
        "Customer Name",
        "Customer Phone",
        ...(Array.isArray(productList)
          ? productList.filter((product) => product !== "All Product")
          : []),
        "Total Quantity",
      ];
    } else {
      return [
        "Customer ID",
        "Customer Name",
        "Customer Phone",
        `${selectedProduct}`,
      ];
    }
  }, [selectedProduct, productList]);

  // Prepare table data based on the selected product
  const tableData = useMemo(() => {
    if (!details || !selectedProduct) {
      return [["No data found"]];
    }

    const customerList =
      selectedProduct === "All Product"
        ? details["Total Quantity"]?.customer_list || []
        : details[selectedProduct]?.customer_list || [];

    return customerList.map((customer) => {
      const row = [
        customer.customer_id || "N/A",
        customer.customer_name || "N/A",
        customer.customer_phone || "N/A",
      ];

      const sortedProducts = productList
        .filter((product) => product !== "All Product")
        .sort();

      sortedProducts.forEach((product) => {
        const productData = customer.products.find(
          (p) => p.product_name === product
        );
        row.push(productData ? productData.quantity : 0);
      });

      if (selectedProduct === "All Product") {
        row.push(row.slice(3).reduce((total, value) => total + (Number(value) || 0), 0));
      }

      return row;
    });
  }, [details, selectedProduct, productList]);

  // Sync table data with export data
  useEffect(() => {
    setExportData(tableData);
  }, [tableData]);

  // Prepare export-selected data based on selected product
  useEffect(() => {
    const prepareExportData = () => {
      if (!exportData || selectedProduct === "All Product") {
        setExportSelectedData(exportData);
        return;
      }

      const productIndex = productList.indexOf(selectedProduct);
      const data = exportData.map((row) => [
        row[0],
        row[1],
        row[2],
        row[productIndex + 3] || 0,
      ]);

      setExportSelectedData(data);
    };

    prepareExportData();
  }, [selectedProduct, exportData, productList]);

  // Fetch data and initialize state
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await fetchCartData();
        
        if (data) {
          setRawData(data);
          const productCustomerMap = mapCartProducts(data);
          setDetails(productCustomerMap);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
    
  // Generate product list
  useEffect(() => {
    if (details) {
      const products = Object.keys(details).filter(
        (key) => key !== "Total Quantity"
      );
      setProductList(["All Product", ...products.sort()]);
    }
  }, [details]);

  // Set columns for the matrix
  useEffect(() => {
    if (productList.length > 0) {
      setColumns([
        "Customer ID",
        "Customer Name",
        "Customer Phone",
        ...productList.filter((p) => p !== "All Product"),
        "Total Quantity",
      ]);
    }
  }, [productList]);

  // Generate details matrix
  useEffect(() => {
    if (columns.length > 0 && rawData) {
      const matrixData = CartMatrix(rawData, columns);
      setDetailsMatrix(matrixData);
    }
  }, [columns, rawData]);

  // Utility to convert data to CSV format
  const convertToCSV = (data) =>
    data.map((row) => row.join(",")).join("\n");

  const formatDataForCSV = () => {
    if (selectedProduct === "All Product") {
      // Return all data for "All Product" selection
      return [
        ["Customer Name", "Customer ID", "Customer Phone", ...productList.slice(1), "Total Quantity"],
        ...(detailsMatrix?.data || [["No Data Found"]]),
      ];
    } else {
      // Prepare data for individual product selection
      const exportData = [];
  
      if (details && selectedProduct && selectedProduct !== "All Product") {
        const customerList = details[selectedProduct]?.customer_list || [];
  
        customerList.forEach((customer) => {
          // Base row with customer information
          const newRow = [
            customer.customer_name || "N/A",
            customer.customer_id || "N/A",
            customer.customer_phone || "N/A",
          ];
  
          // Find and add details for the selected product
          customer.products.forEach(({ product_name, quantity, Timestamp, subscriptionType }) => {
            if (selectedProduct === product_name) {
              exportData.push([
                ...newRow,
                quantity || 0,
                Timestamp ? DateTimeUtil.timestampToDate(Timestamp) : "-",
                Timestamp ? DateTimeUtil.timestampToTimeAMPM(Timestamp) : "-",
                subscriptionType || "-",
              ]);
            }
          });
        });
      }
  
      // Add column headers dynamically
      return [
        ["Customer Name", "Customer ID", "Customer Phone", "Quantity", "Date", "Time", "Subscription Type"],
        ...exportData,
      ];
    }
  };
  

  // Export data as a CSV file
  const exportToCSV = () => {
    const csvData = convertToCSV(formatDataForCSV());
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "abandoned_cart_data.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      {loading && <Loader />}
      <div className="container-scroller">
        <TopPanel />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="home-tab d-flex justify-content-between align-items-center my-2">
                <h3 className="fw-bold text-primary border-bottom pb-2 mb-0">
                  Cart Products
                </h3>
                <button 
                  className="btn btn-primary ms-3"  style={{color:"white"}}
                  onClick={exportToCSV}
                  disabled={loading || exportSelectedData.length === 0}
                >
                  Export to CSV
                </button>
                {/* <Export tableHeader={tableHeader}  tableRow={selectedProduct === "All Product" ? tableData : exportSelectedData}/> */}
              </div>
              <div className="home-tab">
                <div className="tab-content tab-content-basic">
                  <div
                    className="tab-pane fade show active"
                    id="overview"
                    role="tabpanel"
                    aria-labelledby="overview"
                  >
                    <div className="d-flex justify-content-start gap-3 flex-wrap">
                      <Card
                        key={-1}
                        title={details ? details["Total Quantity"].title : "Total Quantity"}
                        value={details ? details["Total Quantity"].quantity: "0"}
                        onClick={() => setSelectedProduct("All Product")}
                        selected={selectedProduct === "All Product"}
                      />
                    {details && (
                      <>
                      {Object.entries(details).map(
                          ([key, value], index) =>
                            key !== "Total Quantity" && (
                              <Card
                                key={index}
                                title={value.title}
                                value={value.quantity}
                                selected={selectedProduct === value.title}
                                onClick={() => setSelectedProduct(value.title)}
                              />
                            )
                        )}
                        </>
                      )}
                      </div>
                  </div>
                </div>
              </div>
              {details && (
                <div className="row">
                  <div className="d-flex flex-column">
                    <div className="row flex-grow">
                      <DetailedCard
                        details={details}
                        selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                        productList={productList}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

const Loader = () => (
  <div className="loader-overlay">
    <div>
      <img alt="loader" style={{ height: "6rem" }} src="images/loader.gif" />
    </div>
  </div>
);

const Card = ({ title, value, selected, onClick }) => (
  <div
    className={`card d-flex align-items-start m-2 ${
      selected ? "text-white border border-light" : ""
    }`}
    style={{
      cursor: "pointer",
      minWidth: "100px",
      backgroundColor: selected ? "#84BF93" : "",
    }}
    onClick={onClick}
  >
    <div className="card-body w-100">
      <p
        className="statistics-title text-end font-bold"
        style={{ fontSize: "1rem" }}
      >
        {title}
      </p>
      <h3 className="rate-percentage text-end">{value}</h3>
    </div>
  </div>
);

const DetailedCard = ({
  details,
  selectedProduct,
  setSelectedProduct,
  productList,
}) => {
  const getCustomersToDisplay = () => {
    return selectedProduct === "All Product"
      ? details["Total Quantity"]?.customer_list || []
      : details[selectedProduct]?.customer_list || [];
  };

  const customersToDisplay = getCustomersToDisplay();

  return (
    <div className="col-lg-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center">
              <h4 className="card-title me-2">Product:</h4>
              <select
                className="form-select"
                value={selectedProduct}
                onChange={(e) => setSelectedProduct(e.target.value)}
                style={{ width: "200px" }}
              >
                {productList.map((product, index) => (
                  <option key={index}>{product}</option>
                ))}
              </select>
            </div>
            <h4 className="card-title">
              Total Customer: {customersToDisplay.length}
            </h4>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="text-center">Customer ID</th>
                  <th className="text-center">Customer Name</th>
                  <th className="text-center">Quantity</th>
                  {selectedProduct !== "All Product" && <th className="text-center">Subscription Type</th>}
                  <th className="text-center">Customer Phone</th>
                  {selectedProduct !== "All Product" && <th className="text-center">Duration</th>}
                  {selectedProduct !== "All Product" && <th className="text-center">Date & Time</th>}
                </tr>
              </thead>
              <tbody>
                {customersToDisplay.length > 0 ? (
                  customersToDisplay.map((row, index) => (
                    <TableRow
                      key={index}
                      row={row}
                      selectedProduct={selectedProduct}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No orders found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const TableRow = ({ row, selectedProduct }) => {
  const [expandRow, setExpandRow] = useState(false);

  const handleRowClick = () => {
    if (selectedProduct === "All Product") {
      setExpandRow(!expandRow);
    } else {
      setExpandRow(false);
    }
  };

  useEffect(() => {
    setExpandRow(false);
    console.log(row);
    console.log(row.Timestamp)
    // console.log(TimeAgo.fromTimestamp(row.Timestamp))
    return () => setExpandRow(false);
  }, [selectedProduct]);
  return (
    <>
      {
        selectedProduct === "All Product" ? (
          <tr className="hover-highlight" onClick={handleRowClick}>
            <td className="text-center">{row.customer_id}</td>
            <td className="text-center">{row.customer_name || "N/A"}</td>
            <td className="text-center">
              {row.products.reduce((acc, product) => acc + product.quantity, 0)}
            </td>
            <td className="text-center">{row.customer_phone || "N/A"}</td>
          </tr>
        ) : (
          <>
            {row.products
              .filter((product) => product.product_name === selectedProduct)
              .map((product, index) => (
                <tr className="hover-highlight" key={index} onClick={handleRowClick}>
                  <td className="text-center">{row.customer_id}</td>
                  <td className="text-center">{row.customer_name || "N/A"}</td>
                  <td className="text-center">{product.quantity}</td>
                  <td className="text-center">{product.subscriptionType || "-"}</td>
                  <td className="text-center">{row.customer_phone || "N/A"}</td>
                  <td className="text-center">
                    {product.Timestamp ? TimeAgo.fromTimestamp(product.Timestamp) : "-"}
                  </td>
                  <td className="text-center">
                    { product.Timestamp ? DateTimeUtil.timestampToDate(product.Timestamp)+ " " + DateTimeUtil.timestampToTimeAMPM(product.Timestamp) : "-"}
                  </td>
                </tr>
              ))}
          </>
        )
      }

      {expandRow && (
        <tr className="hover-highlight" style={{ background: "#f8f9fa" }}>
          <td colSpan="4" className="p-2">
            {row.products && row.products.length > 0 ? (
              <ul className="d-flex flex-wrap mx-4 justify-content-start p-1 list-unstyled">
                {row.products.map(({ product_name, quantity , Timestamp, subscriptionType}, index) => (
                  <li key={index} className="m-1" style={{ flex: "0 1 auto" }}>
                  <div
                    className="card d-flex flex-row align-items-center justify-content-between p-3 shadow-sm gap-2"
                    style={{ maxWidth: "250px" }}
                  >
                    <div className="d-flex flex-column align-items-start" style={{ flex: "1" }}>
                      <p className="statistics-title mb-0 text-truncate fw-bold">{product_name}</p>
                      <span className="text-muted">{subscriptionType || "-"}</span>
                    </div>
                
                    <div className="d-flex flex-column align-items-end" style={{ flex: "1" }}>
                      <h3 className="rate-percentage text-end mb-0 fw-bold">{quantity}</h3>
                      <span className="text-muted">{Timestamp ? TimeAgo.fromTimestamp(Timestamp) : "-"}</span>
                    </div>
                  </div>
                </li>
                
                ))}
              </ul>
            ) : (
              <p>Can't find any product</p>
            )}
          </td>
        </tr>
      )}
    </>
  );
};

export default MarkatingDashboard;
