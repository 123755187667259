import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Navigator from './Navigator';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toastStyles.css';
import GlobalState from './context/GlobalState';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 // <React.StrictMode>
 <>
 <GlobalState>
    <Navigator />
 </GlobalState>
  <ToastContainer autoClose={2000}/>
 </>
     
 // </React.StrictMode>
);
